import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from "js-cookie";
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import { stopVideo } from "@/js/main";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../components/login.vue'),
    },
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '@/components/home.vue'),
        redirect: '/home/real_time',
        children: [
            {
                path: 'sider_set',
                name: 'sider_set',
                component: () => import(/* webpackChunkName: "about" */ '@/views/set.vue'),
                redirect: '/home/sider_set/video-settings',
                children: [
                    {
                        path: 'video-settings',
                        name: 'video-settings',
                        component: () => import(/* webpackChunkName: "about" */ '@/views/set/video-settings.vue'),
                    },
                    {
                        path: 'Network-settings',
                        name: 'Network-settings',
                        component: () => import(/* webpackChunkName: "about" */ '@/views/set/Network-settings.vue'),
                    },
                    {
                        path: 'System-settings',
                        name: 'System-settings',
                        component: () => import(/* webpackChunkName: "about" */ '@/views/set/System-settings.vue'),
                    },
                    {
                        path: 'save-setting',
                        name: 'save-setting',
                        component: () => import(/* webpackChunkName: "about" */ '@/views/set/save-setting.vue'),
                    },
                ]
            },
            {
                path: 'real_time',
                name: 'real_time',
                component: () => import(/* webpackChunkName: "about" */ '../views/real_time.vue'),
            },
            // {
            //     path: 'NotFound',
            //     name: 'NotFound',
            //     component: () => import(/* webpackChunkName: "about" */ '@/components/NotFound.vue'),
            // },
        ]
    },

]
//
// const router = new VueRouter({
//     mode: 'hash',
//     base: process.env.BASE_URL,
//     routes
// })


/* 在创建router实例对象之前，手动覆盖原型链的push来吞掉报错catch */
// 先存储默认底层的push
const originPush = VueRouter.prototype.push
// 覆盖原型链的push
VueRouter.prototype.push = function(location,resolve,reject){
    // this:路由实例对象

    // 判断用户有没有传后面两个可选参数
    if ( resolve || reject ) {
        return originPush.call(this,location,resolve,reject)
    } else {// 用户只传了第一个参数
        /*
        默认底层： catch()方法代码  throw err : 抛出异常
        吞掉报错原理： 重写catch()方法,把默认底层的 throw err给去掉，就不会抛出异常
        */
        return originPush.call(this,location).catch(err => {
            //  throw err
        })
    }
}

const router = new VueRouter({
    routes
})

// 路由守卫
router.beforeEach((to, from, next) => {

    const id = Cookies.get('id');  // 获取id
    // console.log("获取到id", Cookies.get('id'))
    if (id !== undefined) {
        next();
    } else {
        if (to.path === '/') {
            next()
        } else {
            next({
                path: '/'
            })
        }
    }

    if (from.path === '/home/real_time' && to.path === '/') {
        stopVideo(); // 执行你想要的函数
        // 继续导航到目标路由
        next();
    } else {
        // 其他情况，直接继续导航
        next();
    }
})

export default router
