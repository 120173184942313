module.exports = {
    common: {
        aperture_plus: '光圈+',
        aperture_minus: '光圈-',
        focus_plus: '聚焦+',
        focus_minus: '聚焦-',
        amplify_plus: '放大+',
        amplify_minus: '放大-',
        default: '恢复默认',
        Fullscreen: '全屏',
        Bitstream: '码流',
        voice: '声音',
        login: '登录',
        Pan_tilt_speed: '云台速度',
        Doubling: '变倍速度',
        Focusing: '聚焦灵敏',
        Focus_Area: '对焦区域',
        Preset: '预置位',
        mirror: '镜像',
        brightness: '亮度',
        saturation: '饱和度',
        acutance: '锐度',
        contrast: '对比度',
        tone: '色调',
        exposure: '曝光模式',
        shutter: '最大快门',
        gain: '最大增益',
        Backlight: '背光补偿',
        highlight: '强光抑制',
        ExposureCompensation: '曝光补偿',
        FStop: '光圈值',
        flicker: '抗闪烁',
        whitebalance: '白平衡',
        redgain: '红增益',
        Bluegain: '蓝增益',
        Color: '色温',
        tall: '高',
        centre: '中',
        down: '低',
        upper_apex: '顶部',
        hub: '中心',
        foot: '底部',
        all_over: '全幅',
        Main_stream: '主码流',
        Sub_stream: '次码流',
        DivX: '视频编码',
        Configuration: '配置模式',
        resolution: '分辨率',
        FPs: '帧率',
        Bitratetype: '码率类型',
        quality: '质量',
        interval: 'I帧间隔',
        code_rate: '码率',
        emplace: '设置',
        freshen: '刷新',
        HDMI: 'HDMI 时序',
        firing: '启动',
        IPv4site: 'IPv4地址',
        IPv4mask: 'IPV4子网掩码',
        IPv4gateway: 'IPV4默认网关',
        IPv6site: 'IPv6地址',
        IPv6mask: 'IPv6子网掩码',
        IPv6gateway: 'IPv6默认网关',
        MAC_Address: 'MAC地址',
        prefer: '首选DNS',
        standby: '备用DNS',
        AutomaticallyDNS: '自动获取DNS地址',
        AutomaticallyIP: '自动获取IP',
        staticIP: '静态IP',
        staticDNS: '静态DNS',
        AutogainDNS: '自动获取DNS',
        beginOnvif: '启动Onvif',
        metadata_alarm: '开启元数据告警',
        External_IPV4: '外网IPV4地址',
        port: '端口',
        synchronizetime: '允许同步时间',
        synchronization_parameters: '允许同步参数',
        identity_authentication: '进行身份认证',
        Video_control: '视频控制标识',
        Audio_control: '音频控制标识',
        Dialogue_control: '对话控制标识',
        Mtu: 'Mtu',
        beginRtmp: '启动Rtmp',
        Stream_1: '主码流',
        Stream_2: '次码流',
        beginSRT: '启动SRT',
        SRTport: 'SRT端口',
        SRTpassword: 'SRT密码',
        SRTpasswordlen: '键长',
        Password_Length: '密码在10-79个字符之间',
        devicename: '设备名',
        deviceID: '设备ID',
        version: '版本',
        Equipmentime: '设备时间',
        ComputerTime: '电脑时间',
        Manualsettings: '手动设置',
        timezone: '时区',
        openNTP: '启用NTP',
        NTPIPsite: 'NTP IP地址',
        hr: '小时',
        min: '分钟',
        update_cycle: '定时更新周期',
        increase: '增加',
        username: '用户名',
        OldPassword: '原密码',
        cipher: '密码',
        Confirm: '确认密码',
        group: '组',
        modify: '修改',
        cancel: '取消',
        modul: '模块',
        resetting: '重置',
        ReservedIP: '保留本地网络',
        Reservedusername: '保留用户',
        reservedports: '保留网络端口',
        groupID: '组ID',
        realtime: '实时视频',
        Pantilt: '云台控制',
        rbac: '权限管理',
        Equipment: '设备参数',
        reset: '重启',
        MCUversio: 'PTZ',
        Camera: 'SOC',
        Upgradefiles: '升级文件',
        Clickupload: '点击上传',
        promote: '升级',
        Successfully: '设置成功',
        preferably: '最好',
        better: '更好',
        fine: '好',
        poor: '差',
        Worse: '更差',
        worst: '最差',
        open: '开启',
        no_unseal: '不开启',
        grouping_id: '分组ID',
        omit: '删除',
        collect: '编辑',
        electric_lens: '重置电动镜头',
        resetALL: '重置所有',
        VideoCoding: '视频编码',
        networkport: '网络端口',
        user: '用户',
        Sitelocal: '本地网络',
        tableau: '图像',
        bare: '曝光',
        voluntarily: '自动',
        manual: '手动',
        Specify_temperature: '指定色温',
        close: '关闭',
        fliphorizontal: '水平翻转',
        flipvertical: '垂直翻转',
        horizontally_vertically: '水平垂直翻转',
        set_up: '设置',
        true_time: '实时',
        quit: '退出',
        VideoSettings: '视频设置',
        NetworkSettings: '网络设置',
        Systemsettings: '系统设置',
        VideoConfig: '系统配置',
        Videoutput: '视频输出',
        Local_LAN: '本地网络',
        SystemInfo: '系统信息',
        DateTime: '日期时间',
        system_Upgrade: '系统运维',
        USER: '用户管理',
        UTC_IDW: '(UTC-12:00)国际日期变更线西',
        UTC_11: '(UTC-11:00)协调世界时-11',
        UTC_HA: '(UTC-10:00)夏威夷',
        UTC_Alaska: '(UTC-09:00)阿拉斯加',
        UTC_PS: '(UTC-08:00)太平洋标准(美国)',
        UTC_MS: '(UTC-07:00)山地时间(美国和加拿大)',
        UTC_CA: '(UTC-06:00)中美洲',
        UTC_IND: '(UTC-05:00)印第安纳州 (东边)',
        UTC_AT: '(UTC-04:00)大西洋时间(加拿大)',
        UTC_BSB: '(UTC-03:00)巴西利亚',
        UTC_CUT: '(UTC-02:00)协调世界时-02',
        UTC_Azores: '(UTC-01:00)亚速尔群岛',
        UTC_Dublin: '(UTC+00:00)都柏林，爱丁堡，里斯本，伦敦',
        UTC_WCA: '(UTC+01:00)中非西部',
        UTC_Jerusalem: '(UTC+02:00)耶路撒冷',
        UTC_Moscow: '(UTC+03:00)莫斯科，圣披得堡',
        UTC_Louis: '(UTC+04:00)路易港',
        UTC_ISB: '(UTC+05:00)伊斯兰堡，卡拉奇',
        UTC_Dac: '(UTC+06:00)达卡',
        UTC_Bangkok: '(UTC+07:00)曼谷，河内，雅加达',
        UTC_BS: '(UTC+08:00)北京,重庆',
        UTC_ST: '(UTC+09:00)首尔',
        UTC_Sydney: '(UTC+10:00)墨尔本,悉尼',
        UTC_Magadan: '(UTC+11:00)马加丹',
        UTC_Oakland: '(UTC+12:00)奥克兰，惠灵顿',
        UTC_13: '(UTC+13:00)协调世界时+13',
        UTC_Kt: '(UTC+14:00)圣诞岛',
        define: '确定',
        message: '设备需要重启，是否继续',
        prompt: '提示',
        accordVec: '协议版本',
        Transport: '传输协议',
        SIPID: 'SIP 服务器ID',
        SIPdomain: 'SIP 服务器域',
        SIPaddress: 'SIP 服务器地址',
        SIPport: 'SIP 服务端口',
        SIPname: 'SIP 用户名',
        SSOID: 'SIP 用户认证ID',
        THETERM: '注册有效期',
        RegisStatus: '注册状态',
        Heartbeat: '心跳周期',
        LocalSIPort: '本地SIP端口',
        Streamindex: '28181码流索引',
        Regointerval: '注册间隔',
        Maxheartbeat: '最大心跳超时次数',
        channel: '通道号',
        Vi_cencodingID: '视频通道编码ID',
        SerialNum: '序号',
        name: '名称',
        labor: '操作',
        install: '设置',
        mobilize: '调用',
        RestrictionOne: '当前限制选择1个文件',
        Confirmremoval: '确定移除',
        Restarting: '重启中',
        Jumppage: '秒后跳转到登录页面',
        enterpass: '请输入密码',
        PassConfirm: '密码确认',
        Password_error: '密码不正确！',
        Redtuning: '红微调',
        Bluetrim: '蓝微调',
        invocation: '启用',
        Presetdot: '预置点',
        sealin: '保存',
        UserExist: '用户名不存在',
        encodid: '编码ID',
        Enterpassword: '请再次输入密码',
        Repeatpassword: '两次输入密码不一致!',
        Line: '离线',
        online: '在线',
        nullcipher: '密码确认不能为空！',
        Confirm_inconsistency: '两次输入密码不一致，请重新输入！',
        Deleteuser: '您确定要删除此用户吗？',
        Passinconsistency: '密码不一致',
        Cannotuploadfiles: '不能上传非bin文件！',
        filesize: '文件大小不能超过50MB',
        IP_the_scope: '请输入范围内IP',
        no_illegal: '不能输入非法字符',
        Enternumbers: '请输入数字',
        Consistent_stream: '主码流与次码流不能一样！',
        RangePort: '请输入范围内端口',
        RangeCharacters: '请输入范围内字符',
        Frame_empty: '帧率不能为空',
        Input_interval: '必须输入值在区间范围内',
        I_frame_empty: 'I帧间隔不能为空',
        Upload_failed: '校验失败',
        setsrt: '设置失败',
        enteraccount: '请输入账号',
        focus_mode: '聚焦模式',
        manner: '图像风格',
        Standard: '标准',
        PC: 'PC',
        Beautiful: '亮丽',
        Restart: '重启',
        SystemEdition: '系统版本',
        Copy: '复制',
        TimeManually: '请输入 yyyy-mm-dd hh:mm:ss 格式的数值',
        DeviceName: 'NDI®设备名称',
        GroupName: 'NDI®组名称',
        FirmWare: 'NDI®固件版本',
        UserDisplay: '用户名不能一样！',
        Error: '404错误!',
        NotFound: 'Not Found',
        Error_info: '对不起，您正在寻找的页面不存在，尝试检查URL的错误，然后按浏览器上的刷新按钮或尝试在我们的应用程序中找到其他内容',
    }
}
