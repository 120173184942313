import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from '../i18n/lang/local_zh'
import en from '../i18n/lang/local_en'

//引入element的语言包
// import localeLib from 'element-ui/lib/locale';  //本地
// import enLocale from "element-ui/lib/locale/lang/en"; //英文
// import zhLocale from "element-ui/lib/locale/lang/zh-CN";  //中文

Vue.use(VueI18n)

//准备翻译的语言环境信息
const i18n = new VueI18n({
    locale: 'zh', // 语言标识
    silentTranslationWarn: true,// 设置为true 会屏蔽翻译在控制台报出的警告
    messages: {
        'en': en,
        'zh': zh,
    }
})

export default i18n