import { wasmjs } from "@/js/wasm";
import { isWindowsPlatform, getWebSocketAddress } from "@/js/common";

var jsonObj=null;
var jsonVideo=null;
var jsonImage=null;
var wasmPlayer=null;
var isListen = false;

export function initCameraList()
{
    var channel = document.getElementById("channel");
    for(var i = 0; i < jsonObj.ChannelNum; ++i)
    {
        var op = document.createElement("option");
        op.value = i;
        op.text = (i + 1) + "";
        channel.add(op);
    }

    if(channel.options.length<2)
    {
        channel.style.display="none";
        document.getElementById("tchannel").style.display="none";
    }

	if(jsonObj.IsFrontStitchingDev==1 || jsonObj.isDomeDev==1)
	{
		channel.style.display="none";
		document.getElementById("tchannel").style.display="none";
	}
}

export function  initStreamList()
{
    var sel = document.getElementById("stream");
    for(var i = 0; i < jsonObj.StreamNum-1; ++i)
    {
        var op = document.createElement("option");
        op.value = i;
        op.text = (i + 1) + "";
        sel.add(op);
    }
}

export function onClickAlarm(id)
{
    var json={"Ch":0,"Value":1};
    switch(id)
    {
        case 'Alm1':json.Ch=0;break;
        case 'Alm2':json.Ch=1;break;
        case 'Alm3':json.Ch=2;break;
        case 'Alm4':json.Ch=3;break;
    }

    AjaxSubmitEx({url:"/setIOCtl",data:json,cb:function (obj){}});
}

export function initAlarmOutList()
{
    var nCnt=Number(jsonObj.AlarmOutputNum);
    for(var i = 1; i <= nCnt; ++i)
        $("#Alm" + i).css("display", "block").click(function(){onClickAlarm($(this).attr("id"));});

    for(var j = 1+nCnt; j <= 4; j++)
        $("#Alm" + j).css("display", "none").addClass("num-disabled");
}

export function bindEventFunc()
{
    $('#listen').click(function ()
    {
        onClickListen($(this));
    });
    $('#talk').click(function ()
    {
        onClickTalk($(this));//对讲
    });
    $('#snapshot').click(function ()
    {
        onClickSnapshot($(this));//抓拍
    });
    $('#record').click(function ()//记录
    {
        onClickRecord($(this));
    });
}

export function wasmChangeDiv()
{
    var channel = document.getElementById("cameraid").value;
    var stream = document.getElementById("streamid").value;
    wasmDisplayResolution(parseInt(channel),parseInt(stream)+1);
}


export function initVideoWidget()
{
    if(isHasAttr(jsonVideo,'Mirror'))
    {
        $("#iMirror").val(jsonVideo.Mirror);
    }
}

export function initVideoParam(ch)
{
    var json={"ID":0,"Channel":0,"Stream":1};
    json.Channel =ch;
    json.ID      =getvalue("ID");
    AjaxSubmitEx({url:"/GetCfgVEnc",async:false,data:json,cb:function (obj){
        jsonVideo =obj;
        initVideoWidget();
    }});
}

function initImageParam(ch)
{
    var json={"ID":0,"Channel":0,"Profile":0};
    json.ID=getvalue("ID");
    json.Channel=ch;
    json.Profile=0;
    AjaxSubmitEx({url:"/AjaxGetCfgVImg",data:json,async:false,cb:function (obj) {
        jsonImage=obj;
        initImageWidget();
    }});
}

function onAeModeSel(AEMode)
{
    document.getElementById("dlaemaxshuter").style.display="none";
    document.getElementById("dlaemaxgain").style.display="none";
    document.getElementById("dlaefixshuter").style.display="none";
    document.getElementById("dlaefixgain").style.display="none";

    if(AEMode==1)
    {
        document.getElementById("dlaemaxshuter").style.display="block";
        document.getElementById("dlaemaxgain").style.display="block";
    }
    else
    {
        document.getElementById("dlaefixshuter").style.display="block";
        document.getElementById("dlaefixgain").style.display="block";
    }
}

function onAwbModeSel(AwbMode)
{
    document.getElementById("dlsrgain").style.display="none";
    document.getElementById("dlsggain").style.display="none";
    document.getElementById("dlsbgain").style.display="none";

    document.getElementById("dlargain").style.display="none";
    document.getElementById("dlabgain").style.display="none";

    document.getElementById("dlawbctemp").style.display="none";

    if(AwbMode==1) //XVP_AWB_AUTO  1
    {
        document.getElementById("dlargain").style.display="block";
        document.getElementById("dlabgain").style.display="block";
    }
    else if(AwbMode==11) //XVP_AWB_CUSTOM
    {
        document.getElementById("dlawbctemp").style.display="block";
    }
    else if(AwbMode==2) //XVP_AWB_MANUAL
    {
        document.getElementById("dlsrgain").style.display="block";
        document.getElementById("dlsggain").style.display="block";
        document.getElementById("dlsbgain").style.display="block";
    }
}



function initImageWidget()
{
    /////////////////////////////////////////////////////////////////////////////////
    //AF
    if(isHasAttr(jsonImage.AF,'DZoomOpen'))
    {   $("#digitalzoom").val(jsonImage.AF.DZoomOpen); }

    if(isHasAttr(jsonImage.AF,'Sensitivity'))
    {   $("#saflevel").val(jsonImage.AF.Sensitivity); }

    if(isHasAttr(jsonImage.AF,'Zone'))
    {   $("#safzone").val(jsonImage.AF.Zone); }

    /////////////////////////////////////////////////////////////////////////////////
    //ISP
    if(isHasAttr(jsonImage.ISP,'ColorMode'))
    {   $("#iColorMode").val(jsonImage.ISP.ColorMode); }

    if(isHasAttr(jsonImage.ISP,'Brightness'))
    {
        $("#bright").val(jsonImage.ISP.Brightness);
        $("#bright").siblings('.bg-percent').text( jsonImage.ISP.Brightness );
    }

    if(isHasAttr(jsonImage.ISP,'Saturation'))
    {
        $("#saturation").val(jsonImage.ISP.Saturation);
        $("#saturation").siblings('.bg-percent').text( jsonImage.ISP.Saturation );
    }

    if(isHasAttr(jsonImage.ISP,'Sharpness'))
    {
        $("#sharp").val(jsonImage.ISP.Sharpness);
        $("#sharp").siblings('.bg-percent').text( jsonImage.ISP.Sharpness );
    }

    if(isHasAttr(jsonImage.ISP,'Contrast'))
    {
        $("#contrast").val(jsonImage.ISP.Contrast);
        $("#contrast").siblings('.bg-percent').text( jsonImage.ISP.Contrast );
    }

    if(isHasAttr(jsonImage.ISP,'Hue'))
    {
        $("#hue").val(jsonImage.ISP.Hue);
        $("#hue").siblings('.bg-percent').text( jsonImage.ISP.Hue );
    }

    if(isHasAttr(jsonImage.ISP,'WDRMode'))
    {
        $("#wdrmode").val(jsonImage.ISP.WDRMode);
        $("#wdrmode").siblings('.bg-percent').text( jsonImage.ISP.WDRMode );
    }

    if(isHasAttr(jsonImage.ISP,'GammaMode'))
    {
        $("#gammmode").val(jsonImage.ISP.GammaMode);
    }

    if(isHasAttr(jsonImage.ISP,'Noise'))
    {
        $("#slider_noise2").val(jsonImage.ISP.Noise);
        $("#slider_noise2").siblings('.bg-percent').text( jsonImage.ISP.Noise );
    }

    if(isHasAttr(jsonImage.ISP,'Noise3'))
    {
        $("#slider_noise3").val(jsonImage.ISP.Noise3);
        $("#slider_noise3").siblings('.bg-percent').text( jsonImage.ISP.Noise3);
    }

    if(isHasAttr(jsonImage.ISP,'Defog'))
    {
        $("#slider_defog").val(jsonImage.ISP.Defog);
        $("#slider_defog").siblings('.bg-percent').text( jsonImage.ISP.Defog);
    }

    /////////////////////////////////////////////////////////////////////////
    //AE
    if(isHasAttr(jsonImage.AE,'AEMode'))
    {
        $("#aeshutmode").val(jsonImage.AE.AEMode);
        onAeModeSel(jsonImage.AE.AEMode);
    }

    if(isHasAttr(jsonImage.AE,'MaxShutterTime'))
    {
        $("#aemaxshuter").val(jsonImage.AE.MaxShutterTime);

        var text1="1/"+Math.trunc(1000000.0/jsonImage.AE.MaxShutterTime);
        $("#aemaxshuter").siblings('.bg-percent').text(text1);
    }

    if(isHasAttr(jsonImage.AE,'MaxGain'))
    {
        $("#aemaxgain").val(jsonImage.AE.MaxGain);
        $("#aemaxgain").siblings('.bg-percent').text( jsonImage.AE.MaxGain);
    }

    if(isHasAttr(jsonImage.AE,'FixShutterTime'))
    {
        $("#aefixshuter").val(jsonImage.AE.FixShutterTime);
        var text1="1/"+Math.trunc(1000000.0/jsonImage.AE.FixShutterTime);

        $("#aefixshuter").siblings('.bg-percent').text( text1);
    }

    if(isHasAttr(jsonImage.AE,'FixGain'))
    {
        $("#aefixgain").val(jsonImage.AE.FixGain);
        $("#aefixgain").siblings('.bg-percent').text( jsonImage.AE.FixGain);
    }

    if(isHasAttr(jsonImage.AE,'BLC'))
    {
        if(jsonImage.AE.BLC=="1")
            $('#caeblc').prop("checked",true);
        else
            $('#caeblc').prop("checked",false);
    }

    if(isHasAttr(jsonImage.AE,'HL'))
    {
        if(jsonImage.AE.HL=="1")
            $('#caehlc').prop("checked",true);
        else
            $('#caehlc').prop("checked",false);
    }

    if(isHasAttr(jsonImage.AE,'ExpComp'))
    {
        $("#aeexpcomp").val(jsonImage.AE.ExpComp);
        $("#aeexpcomp").siblings('.bg-percent').text( jsonImage.AE.ExpComp);
    }

    if(isHasAttr(jsonImage.AE,'AntiFlickerMode'))
    {
         $("#aeantiflicker").val(jsonImage.AE.AntiFlickerMode);
    }

    if(isHasAttr(jsonImage.AE,'FN'))
    {
        $("#aeirvalue").val(jsonImage.AE.FN);
        $("#aeirvalue").siblings('.bg-percent').text( jsonImage.AE.FN);
    }


    ///////////////////////////////////////////////////////////////////////
    //AWB
    if(isHasAttr(jsonImage.AWB,'AWBMode'))
    {
        $("#awbmode").val(jsonImage.AWB.AWBMode);
        onAwbModeSel(jsonImage.AWB.AWBMode);
    }

    if(isHasAttr(jsonImage.AWB,'RGain'))
    {
        $("#srgin").val(jsonImage.AWB.RGain);
        $("#srgin").siblings('.bg-percent').text(jsonImage.AWB.RGain);
    }

    if(isHasAttr(jsonImage.AWB,'GRGain'))
    {
        $("#sggain").val(jsonImage.AWB.GRGain);
        $("#sggain").siblings('.bg-percent').text(jsonImage.AWB.GRGain);
    }

    if(isHasAttr(jsonImage.AWB,'BGain'))
    {
        $("#sbgain").val(jsonImage.AWB.BGain);
        $("#sbgain").siblings('.bg-percent').text(jsonImage.AWB.BGain);
    }

    if(isHasAttr(jsonImage.AWB,'AutoRGain'))
    {
        $("#argain").val(jsonImage.AWB.AutoRGain);
        $("#argain").siblings('.bg-percent').text(jsonImage.AWB.AutoRGain);
    }

    if(isHasAttr(jsonImage.AWB,'AutoBGain'))
    {
        $("#abgain").val(jsonImage.AWB.AutoBGain);
        $("#abgain").siblings('.bg-percent').text(jsonImage.AWB.AutoBGain);
    }

    if(isHasAttr(jsonImage.AWB,'CustomTemp'))
    {  $("#awbctemp").val(jsonImage.AWB.CustomTemp); }
}

function initWeb()
{
    var chn = 0;
    var stream = 0;
    document.getElementById("cameraid").value=chn;
    document.getElementById("streamid").value=stream;
    if(!isWindowsPlatform()) {
        wasmDisplayResolution(chn,stream+1);
        $(window).resize(wasmChangeDiv);
    }

    var sendjson={"ID":0};
    sendjson.ID=getvalue("ID");
    AjaxSubmitEx({url:"/getCfgMain",async:false,data:sendjson,cb:function (obj){
        jsonObj=obj;
        initWidget();
    }});
    initVideoParam(chn);
    initImageParam(chn);
}

function initWidget()
{
    if (isWindowsPlatform())
    {
        document.form1.pa1.ServerIP =window.location.hostname;
        document.form1.pa1.ServerPort=getActivePort();
        document.form1.pa1.ShowType=0;
        document.form1.pa1.Language=getvalue("lg");
        document.form1.pa1.SetIdentity(getvalue("ID"));
        document.form1.pa1.Login();
    }

    startVideo();

    bindEventFunc();
    initCameraList();
    initStreamList();
    //initAlarmOutList();
    //init ptz
    //changeType();
    initPTZEnableStatus();
}

function uninitWeb()
{
    stopVideo();
}

export function fPlayer(urlPath,channel,stream)
{
    if (wasmjs.isSupported())
    {
        wasmPlayer = wasmjs.createPlayer({type: 'play',url: urlPath,ch: channel,stream: stream});
        wasmPlayer.videoPlay();
    }
}

export function startVideo()
{
    var channel = document.getElementById("cameraid").value;
    var stream = document.getElementById("streamid").value;

    console.log("startvideo")
    if (isWindowsPlatform())
    {
        document.form1.pa1.StartPreview(parseInt(channel), parseInt(stream));
    }
    else
    {
        fPlayer(getWebSocketAddress(),channel,stream);
    }
}


export function stopVideo()
{
    // console.log("执行")
    if(isWindowsPlatform())
    {
        document.form1.pa1.EnableAudio(false);
        document.form1.pa1.EnableTalk(false);
        document.form1.pa1.EnableRecord(false);
        document.form1.pa1.StopPreview();
        document.form1.pa1.Logout();
    }
    else
    {
        if(wasmPlayer)
        {
            wasmPlayer.destroy();
            wasmPlayer=null;
        }
    }
}

function wasmDisplayResolution(chn,stream)
{
    var actw = $("#activearea").width();
    var acth = $("#activearea").height();
    var obj = getPalySize({chn: chn,stream:stream,lw:actw,lh:acth});
    document.getElementById('canvasplay').style.width = obj.w+'px';
    document.getElementById('canvasplay').style.height = obj.h+'px';
    document.getElementById('canvasplay').style.marginLeft = (actw-obj.w)/2 + 'px';
}

function changeStreamId(obj)
{
    var streamid = obj.value;
    if (document.getElementById("streamid").value != streamid)
    {
        document.getElementById("streamid").value = streamid;

        var channel = document.getElementById("cameraid").value;
        var stream = document.getElementById("streamid").value;
        if (isWindowsPlatform())
        {
            document.form1.pa1.StopPreview();
            document.form1.pa1.StartPreview(parseInt(channel), parseInt(stream));
            document.form1.pa1.SwitchStream(parseInt(stream));
        }
        else
        {
            wasmDisplayResolution(parseInt(channel), parseInt(stream)+1);
            stopVideo();
            startVideo();
        }
    }
}

function changePlayerMode(obj)//切换模式
{
    var val = obj.value;
    if (val == 0)
    {
        if (isWindowsPlatform())
            document.form1.pa1.StreamMode=0;
    }
    else
    {
        if (isWindowsPlatform())
            document.form1.pa1.StreamMode=1;
    }
}

function changeCameraId(obj)
{
    var cameraid = obj.value;
    if (document.getElementById("cameraid").value != cameraid)
    {
        document.getElementById("cameraid").value = cameraid;

        var channel = document.getElementById("cameraid").value;
        var stream = document.getElementById("streamid").value;
        if (isWindowsPlatform())
        {
            document.form1.pa1.StopPreview();
            document.form1.pa1.StartPreview(parseInt(channel), parseInt(stream));
            document.form1.pa1.SwitchStream(parseInt(stream));
        }
        else
        {
            stopVideo();
            startVideo();
        }

        initVideoParam(channel);
        initImageParam(channel);
    }
}

function onClickListen(obj)//监听
{
    var curStat = obj.val();
    curStat = 1 - curStat;
    if (curStat == 1)
    {
        isListen = true;
        if (isWindowsPlatform())
            document.form1.pa1.EnableAudio(true);
        else
            wasmPlayer.startAudio();
    }
    else
    {
        isListen = false;
        if (isWindowsPlatform())
            document.form1.pa1.EnableAudio(false);
        else
            wasmPlayer.closeAudio();
    }
    obj.val(curStat);
    if (curStat == 1)
    {
        $('#listen').removeClass().addClass("btn-ac");
    }
    else
    {
        $('#listen').removeClass().addClass("btn-s");
    }
}

function onClickTalk(obj)//对话
{
    var curStat = obj.val();
    curStat = 1 - curStat;
    if (curStat == 1)
    {
        if (isWindowsPlatform())
        {
            document.form1.pa1.EnableTalk(true);
        }
    }
    else
    {
        if (isWindowsPlatform())
        {
            document.form1.pa1.EnableTalk(false);
        }
    }
    obj.val(curStat);
    if (curStat == 1)
    {
        $('#talk').removeClass().addClass("btn-ac");
    }
    else
    {
        $('#talk').removeClass().addClass("btn-s");
    }
}

function onClickSnapshot(obj)
{
    if (isWindowsPlatform())
    {
        document.form1.pa1.Snapshot();
    }
}

function onClickRecord(obj)//记录
{
    var curStat = obj.val();
    curStat = 1 - curStat;
    if (curStat == 1)
    {
        if (isWindowsPlatform())
          document.form1.pa1.EnableRecord(true);
    }
    else
    {
        if (isWindowsPlatform())
            document.form1.pa1.EnableRecord(false);
    }
    obj.val(curStat);
    if (curStat == 1)
    {
        $('#record').removeClass().addClass("btn-ac");
    }
    else
    {
        $('#record').removeClass().addClass("btn-s");
    }
}

//////////////////Right/////////////////////////////////////////
//PTZ选项
function changeType()
{

}

function initPresetList()
{

}

function initTourList()
{

}

function initTrackList()
{

}

/////////////////////////////////////////////////////////////
//1:up 2:down 3:left 4:right 5:middle 6:irisinc 7:irisdec 8:zoomin 9:zoomout 10:focusnear 11:focusfar
//12:zoomOutbyStep 13:zoomInbyStep 14:focusfarbystep 15:focusnearbystep
function operatorPTZ(val)
{
	var channel = $("#channel").val();
	var speed	=parseInt($("#ptzspeed").val());
	var speed2	=parseInt($("#zoomspeed").val());

    if(isWindowsPlatform())
    {
        if(val==1){
            document.form1.pa1.PTZControl(0,channel,0,speed);//上  格式：命令+通道+data1+data2
        }else if(val==2){
            document.form1.pa1.PTZControl(1,channel,0,speed);//下
        }else if(val==3){
            document.form1.pa1.PTZControl(2,channel,speed,0);//左
        }else if(val==4){
            document.form1.pa1.PTZControl(3,channel,speed,0);//右
        }else if(val==5){
            document.form1.pa1.PTZControl(14,channel,0,0);//停
        }else if(val==6){
            document.form1.pa1.PTZControl(10,channel,0,0);//光圈+
        }else if(val==7){
            document.form1.pa1.PTZControl(11,channel,0,0);//光圈-
        }else if(val==8){
            document.form1.pa1.PTZControl(12,channel,speed,speed2);//放大
        }else if(val==9){
            document.form1.pa1.PTZControl(13,channel,speed,speed2);//放小
        }else if(val==10){
            document.form1.pa1.PTZControl(9,channel,speed,speed2);//聚焦+
        }else if(val==11){
            document.form1.pa1.PTZControl(8,channel,speed,speed2);//聚焦-
        }
    }else//版端
    {
        var json={"Channel":0,"PtzCmd":0,"param1":0,"param2":0};
        json.Channel    = channel;
        json.param1     = speed;
		json.param2     = speed2;
        switch(val)
        {
            case 1:json.PtzCmd=0;break;
            case 2:json.PtzCmd=1;break;
            case 3:json.PtzCmd=2;break;
            case 4:json.PtzCmd=3;break;
            case 5:json.PtzCmd=14;break;
            case 6:json.PtzCmd=10;break;
            case 7:json.PtzCmd=11;break;
            case 8:json.PtzCmd=12;break;
            case 9:json.PtzCmd=13;break;
            case 10:json.PtzCmd=9;break;
            case 11:json.PtzCmd=8;break;
            default:json.PtzCmd=5;break;
        }
        AjaxSubmitEx({url:"/setPTZCmd",async:false,data:json,cb:function (obj){}});
    }
}

function click3DButton(obj)
{
    var channel =  $("#channel").val();
    var curStat = obj.val();
    curStat = 1-curStat;
    if(isWindowsPlatform())
    {
        if(curStat == 1)
            document.form1.pa1.EnablePtz3D(true);
        else
            document.form1.pa1.EnablePtz3D(false);
    }

    obj.val(curStat);
    if(curStat == 1)
        $('#PTZOrientation').addClass("see-active");
    else
        $('#PTZOrientation').removeClass("see-active");
}

function clickWipers(obj)
{
    var channel =  $("#channel").val();
    var curStat = obj.val();
    curStat = 1-curStat;
    if(curStat == 1)
    {
        if(isWindowsPlatform())
            document.form1.pa1.PTZControl(19,channel,0,0);
    }else
    {
        if(isWindowsPlatform())
            document.form1.pa1.PTZControl(20,channel,0,0);
    }

    obj.val(curStat);
    if(curStat == 1)
        $('#PTZWipers').addClass("see-active");
    else
        $('#PTZWipers').removeClass("see-active");
}

function clickSwitchLightButton(obj)
{
    var channel =  $("#channel").val();
    var curStat = obj.val();
    curStat = 1-curStat;
    if(curStat == 1)
    {
        if(isWindowsPlatform())
            document.form1.pa1.PTZControl(17,channel,0,0);
    }else
    {
        if(isWindowsPlatform())
            document.form1.pa1.PTZControl(18,channel,0,0);
    }

    obj.val(curStat);
    if(curStat == 1)
        $('#PTZSwitchLight').addClass("see-active");
    else
        $('#PTZSwitchLight').removeClass("see-active");
}

function operatorPTZExt(id,val)
{
    if(isWindowsPlatform())
    {
        var channel =  $("#channel").val();
        if(id==1){
            document.form1.pa1.PTZControl(24,channel,0,val);//present
        }else if(id==2){
            document.form1.pa1.PTZControl(29,channel,val,0);//tour
        }else if(id==3){
            document.form1.pa1.PTZControl(108,channel,val,0);//track
        }
    }
}

function PresetTableSubmit(ch,id)
{
    var tmpjson= {
            "setPreset":"creat",
            "Channel":0,
            "PresetIndex":0,
            "PresetName":0
        };

    tmpjson.Channel=ch;
    tmpjson.PresetIndex=id;
    tmpjson.PresetName=encodeURIComponent("Preset"+id);

    AjaxSubmitEx({url:"/setPTZVideo",data:tmpjson,cb:function (obj) {
        PromptMessageEx(obj);
    }});
    return true;
}

function addpreset(ch,id)
{
    var bRet=PresetTableSubmit(ch,id);
    if(bRet)
    {
        var json={"Channel":0,"PtzCmd":23,"param1":0,"param2":0};//版端
        json.Channel= ch;
        json.param2= id;
        AjaxSubmitEx({url:"/setPTZCmd",data:json,cb:function (obj) {

        }});
    }
}

function delpreset(ch,id)
{
    var json={"Channel":0,"PtzCmd":25,"param1":0,"param2":0};//版端
    json.Channel= ch;
    json.param2= id;
    AjaxSubmitEx({url:"/setPTZCmd",data:json,cb:function (obj) {
    }});

    //提交版端
    var sendjson={"setPreset":"delete","PresetIndex":0};
    sendjson.PresetIndex=id;
    AjaxSubmitEx({url:"/setPTZVideo",data:sendjson,cb:function (obj) {

    }});
}

function callpreset(ch,id)
{
    var json={"Channel":0,"PtzCmd":24,"param1":0,"param2":0};//版端
        json.Channel= ch;
        json.param2= id;
        AjaxSubmitEx({url:"/setPTZCmd",data:json,cb:function (obj) {

    }});
}

function onClickPreset(val)
{
   var channel=$("#channel").val();
   var presetid=$("#inpresetid").val();

  // alert("cmd="+val+",ch="+channel+",pid="+presetid);
   if(val==0){
        addpreset(channel,presetid);
   }
   else if(val==1)
   {
        callpreset(channel,presetid);
   }
   else if(val==2)
   {
        delpreset(channel,presetid);
   }
}




////////////////ptz////////

/* 用来触发ptz按钮  var oper_status=[0];
 * oper: 0:悬空  1:鼠标进入  2:鼠标移出
 * status：0:悬空 1:按下
 */
var tigger= [{oper:0,status:0},{oper:0,status:0},{oper:0,status:0},{oper:0,status:0}];
function ptzBtnBind(index,obj,down,up)
{
    function ptzbtover()
    {
        tigger[index].oper=1;
        tigger[index].status=0;
    }

    function ptzbtout()
    {
        if(tigger[index].oper==1)
        {
            if(tigger[index].status)//如果已经按下了,才发送stop
            {
                perator(5);
                tigger[index].status=0;
            }
            tigger[index].oper=0;
        }
    }

    function ptzbtdown(type)
    {
        if(tigger[index].oper==1)
        {
            operatorPTZ(type);
            tigger[index].status=1;
        }
    }

    function ptzbtup(type)
    {
        if(tigger[index].oper==1)
        {
            perator(5);
            tigger[index].status=0;
        }
    }

    function perator(type)
    {
        operatorPTZ(type);
    }

    obj.mouseover(function(){
        ptzbtover();
    });

    obj.mousedown(function(){
        ptzbtdown(down);
    });

    obj.mouseup(function(){
        ptzbtup(up);
    });

    obj.mouseout(function(){
        ptzbtout();
    });
}

function initPTZEnableStatus()
{
    //if('1'!=jsonObj.isDomeDev)
    //    document.getElementById("Trackset").style.display="none";
    enablePTZWidgets();
}

function enablePTZWidgets()
{
    $('#TrackApply').removeClass().addClass("btn-s  btn").click(function(){clickTrackApply();});
    $('#Trackset').removeClass().addClass("btn-s  btn").click(function(){onclicksetptz();});

    $('#PTZOrientation').removeClass("see-no").click(function(){click3DButton($(this));});
    $('#PTZWipers').removeClass("see-no").click(function(){clickWipers($(this));});
    $('#PTZSwitchLight').removeClass("see-no").click(function(){clickSwitchLightButton($(this));});

    $('#Zoom_m').mousedown(function(){operatorPTZ(9);}).mouseup(function(){operatorPTZ(5);});
    $('#Focus_m').mousedown(function(){operatorPTZ(10);}).mouseup(function(){operatorPTZ(5);});
    $('#Iris_m').mousedown(function(){operatorPTZ(6);}).mouseup(function(){operatorPTZ(5);});
    $('#Zoom_a').mousedown(function(){operatorPTZ(8);}).mouseup(function(){operatorPTZ(5);});
    $('#Focus_a').mousedown(function(){operatorPTZ(11);}).mouseup(function(){operatorPTZ(5);});
    $('#Iris_a').mousedown(function(){operatorPTZ(7);}).mouseup(function(){operatorPTZ(5);});

    //$('#PTZUp').mousedown(function(){operatorPTZ(1);}).mouseup(function(){operatorPTZ(5);});
    //$('#PTZLeft').mousedown(function(){operatorPTZ(3);}).mouseup(function(){operatorPTZ(5);});
    //$('#PTZMid').mousedown(function(){operatorPTZ(5);}).mouseup(function(){operatorPTZ(5);});
    //$('#PTZRight').mousedown(function(){operatorPTZ(4);}).mouseup(function(){operatorPTZ(5);});
    //$('#PTZDown').mousedown(function(){operatorPTZ(2);}).mouseup(function(){operatorPTZ(5);});

    //修复ptz按钮移出按钮后,不能停止的问题
    $('#PTZMid').mousedown(function(){operatorPTZ(5);}).mouseup(function(){operatorPTZ(5);});
    ptzBtnBind(0,$('#PTZUp'),1,5);
    ptzBtnBind(1,$('#PTZDown'),2,5);
    ptzBtnBind(2,$('#PTZLeft'),3,5);
    ptzBtnBind(3,$('#PTZRight'),4,5);
}

//调用
function clickTrackApply()
{
    var ApplyID=document.getElementById("tListID");
    if(ApplyID.options.length==0)
        return;
    else
        ApplyID=document.getElementById("tListID").value;

    var type=$("#funcPTZCall").val();
    if(type == 1)//present
        operatorPTZExt(1,ApplyID);
    else if(type == 2)//tour
        operatorPTZExt(2,ApplyID);
    else if(type == 3)//track
        operatorPTZExt(3,ApplyID);
}
//设置
function onclicksetptz()
{
    var id=getvalue("ID");
    id="ID="+id;
    var lg=getvalue("lg");
    lg="lg="+lg;

    var version=getvalue("version");
    version="version="+version;

    var vUrl = './ptz.asp?'+id+"&"+lg+"&"+version;
    window.location.href=vUrl;
}

//////////////////////////////////////////////////////////////////////
//AF
function changeDigitalZoom()
{
    var profile=0;
    var jsonobj={"saveAllChannel":0,"setChannel":0,"DZoomOpen":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.DZoomOpen=$("#digitalzoom").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeFocusLevel()
{
    var profile=0;
    var jsonobj={"saveAllChannel":0,"setChannel":0,"AFSensitivity":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.AFSensitivity=$("#saflevel").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeFocusZone()
{
    var profile=0;
    var jsonobj={"saveAllChannel":0,"setChannel":0,"Zone":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.Zone=$("#safzone").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeFocusType(obj)
{
    var curStat = obj.val();
    var profile=0;
    var jsonobj={"saveAllChannel":0,"setChannel":0,"AFmode":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.AFmode=curStat;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

///////////////////////////////////////////////////////////////////////////////
//
//图像
function changeMirror()
{
    var json = { "saveAllChannel":0, "setChannel" : 0, "Mirror" :0 };
    json.setChannel=document.getElementById("cameraid").value;
    json.Mirror=document.getElementById("iMirror").value;

    AjaxSubmitEx({url:"/SetCfgVEnc",data:json,async:false});
}

function changeColorMode()
{
    var profile =0;
    var jsonobj = {"saveAllChannel":0,"setChannel":0,"colormode":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.colormode=$("#iColorMode").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changedbright()
{
    var profile =0;
    var bright  = $("#bright").val();
    var jsonobj = {"saveAllChannel":0,"setChannel":0,"bright":0,"profile":0};

    jsonobj.setChannel=$("#channel").val();
    jsonobj.bright=bright;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}
function changedsaturation()
{
    var profile =0;
    var saturation = $("#saturation").val();

    var jsonobj = {"saveAllChannel":0,"setChannel":0,"saturation":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.saturation=saturation;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}
function changedbsharp()
{
    var profile =0;
    var sharp = $("#sharp").val();
    var jsonobj = {"saveAllChannel":0,"setChannel":0,"sharp":0,"profile":0};

    jsonobj.setChannel=$("#channel").val();
    jsonobj.sharp=sharp;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}
function  changedcontrast()
{
    var profile =0;
    var contrast = $("#contrast").val();

    var jsonobj = {"saveAllChannel":0,"setChannel":0,"contrast":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.contrast=contrast;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}
function changedhue()
{
    var profile =0;
    var hue = $("#hue").val();

    var jsonobj = {"saveAllChannel":0,"setChannel":0,"Hue":0,"profile":0};

    jsonobj.setChannel=$("#channel").val();
    jsonobj.Hue=hue;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changewdr()
{
    var profile=0;
    var wdr = $("#wdrmode").val();
    var jsonobj={"saveAllChannel":0,"setChannel":0,"wdr":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.wdr=wdr;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changegamma()
{
    var profile=0;
    var jsonobj={"saveAllChannel":0,"setChannel":0,"gamma":0,"profile":0};

    jsonobj.setChannel=$("#channel").val();
    jsonobj.gamma=$("#gammmode").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changed2d()
{
    var profile =0;
    var jsonobj = {"saveAllChannel":0,"setChannel":0,"noise":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.noise= $("#slider_noise2").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changed3d()
{
    var profile =0;
    var jsonobj = {"saveAllChannel":0,"setChannel":0,"NR3D":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.NR3D=$("#slider_noise3").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeddefog()
{
    var profile =0;
    var jsonobj = {"saveAllChannel":0,"setChannel":0,"defogGain":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.defogGain=$("#slider_defog").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//AE
function changeAEMode()
{
    var profile=0;
    var AEMode=$("#aeshutmode").val();
    var jsonobj = {"saveAllChannel":0,"setChannel":0,"shuttermode":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.shuttermode=AEMode;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,cb:function (obj){
        onAeModeSel(AEMode);
    }});
}

function changeAeFixGain()
{
    var profile=0;
    var jsonobj={"saveAllChannel":0,"setChannel":0,"fixgain":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.fixgain=$("#aefixgain").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeAeMaxGain()
{
    var profile=0;
    var jsonobj={"saveAllChannel":0,"setChannel":0,"maxgain":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.maxgain=$("#aemaxgain").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeExpComp()
{
    var profile=0;
    var jsonobj={"saveAllChannel":0,"setChannel":0,"ExpComp":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.ExpComp=$("#aeexpcomp").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeAeMaxShuter()
{
    var profile=0;
    var jsonobj={"saveAllChannel":0,"setChannel":0,"maxshutter":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.maxshutter=$("#aemaxshuter").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeAeFixShuter()
{
    var profile=0;
    var jsonobj={"saveAllChannel":0,"setChannel":0,"fixshutter":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.fixshutter=$("#aefixshuter").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeAeIrValue()
{
    var profile=0;
    var jsonobj={"saveAllChannel":0,"setChannel":0,"FN":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.FN=$("#aeirvalue").val();
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

//背光补偿
function changeBLC()
{
    var profile=0;

    var vblc=0;
    if(document.getElementById("caeblc").checked)
        vblc=1;

    var jsonobj={"saveAllChannel":0,"setChannel":0,"BLC":0,"profile":0};

    jsonobj.setChannel=$("#channel").val();
    jsonobj.BLC=vblc;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeHLC()
{
    var profile=0;

    var vHlc=0;
    if(document.getElementById("caehlc").checked)
    vHlc=1;

    var jsonobj={"saveAllChannel":0,"setChannel":0,"HDR":0,"profile":0};

    jsonobj.setChannel=$("#channel").val();
    jsonobj.HDR=vHlc;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeAntiFlicker()
{
    var value = $("#aeantiflicker").val();

    var profile=0;
    var jsonobj = {"saveAllChannel":0,"setChannel":0,"flickerless":0,"profile":0};

    jsonobj.setChannel=$("#channel").val();
    jsonobj.flickerless=value;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}



/////////////////////////////////////////////////////////////////////////////////////
//AWB
function changeAwbMode()
{
    var profile=0;
    var awbmode = $("#awbmode").val();
    var jsonobj={"saveAllChannel":0,"setChannel":0,"awbmode":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.awbmode=awbmode;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,cb:function (obj){
        onAwbModeSel(awbmode);
    }});
}

function changeRGain()
{
    var profile =0;
    var RGain=$("#srgin").val();
    var jsonobj={"saveAllChannel":0,"setChannel":0,"RGain":0,"profile":0};

    jsonobj.setChannel=$("#channel").val();
    jsonobj.RGain=RGain;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeGGain()
{
    var profile =0;
    var GGain=$("#sggain").val();
    var jsonobj={"saveAllChannel":0,"setChannel":0,"GRGain":0,"GBGain":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.GRGain=GGain;
    jsonobj.GBGain=GGain;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

//BG
function changeBGain()
{
    var profile =0;
    var BGain=$("#sbgain").val();
    var jsonobj={"saveAllChannel":0,"setChannel":0,"BGain":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.BGain=BGain;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeARGain()
{
    var profile =0;
    var BGain=$("#argain").val();
    var jsonobj={"saveAllChannel":0,"setChannel":0,"AutoRGain":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.AutoRGain=BGain;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeABGain()
{
    var profile =0;
    var BGain=$("#abgain").val();
    var jsonobj={"saveAllChannel":0,"setChannel":0,"AutoBGain":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.AutoBGain=BGain;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function changeAwbCtemp()
{
    var profile=0;
    var value = $("#awbctemp").val();
    var jsonobj={"saveAllChannel":0,"setChannel":0,"CustomTemp":0,"profile":0};
    jsonobj.setChannel=$("#channel").val();
    jsonobj.CustomTemp=value;
    jsonobj.profile=profile-1<0?0:profile-1;
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false});
}

function senddata(id)
{
    switch(id)
    {
        case "bright" : changedbright();break;
        case "saturation" : changedsaturation();break;
        case "sharp" : changedbsharp();break;
        case "contrast" : changedcontrast();break;
        case "hue" : changedhue();break;
        case "wdrmode": changewdr(); break;
        case "slider_noise2" : changed2d();break;
        case "slider_noise3" : changed3d();break;
        case "slider_defog" : changeddefog();break;

        case "aemaxshuter" : changeAeMaxShuter();break;
        case "aefixshuter" : changeAeFixShuter();break;
        case "aeirvalue" : changeAeIrValue();break;

        case "aemaxgain" : changeAeMaxGain();break;
        case "aefixgain" : changeAeFixGain();break;
        case "aeexpcomp" : changeExpComp();break;



        case "srgin" : changeRGain();break;
        case "sggain" : changeGGain();break;
        case "sbgain" : changeBGain();break;

        case "argain" : changeARGain();break;
        case "abgain" : changeABGain();break;

        default:break;
    }
}

function onClickMainCtrl(val)
{
    document.getElementById("main_reset").style.display="none";
    document.getElementById("main_resetbk").style.display="none";
    if(val==0)
    {
        document.getElementById("main_resetbk").style.display="block";
    }
    else
    {
        document.getElementById("main_reset").style.display="block";
    }
}

function onRefreshParam()
{
    var channel = document.getElementById("cameraid").value;
    var stream = document.getElementById("streamid").value;
    initVideoParam(channel);
    initImageParam(channel);
}

function resetMain()
{
    var jsonobj = {"resetImg":1};
    AjaxSubmitEx({url:"/AjaxSetCfgVImg",data:jsonobj,async:false,cb:function (obj) {
        if(obj.State=="infoSuccess")
        {
            msgtooltip(getProName("com_infosuccess"));
            onRefreshParam();
        }
        else
        {
            msgtooltip(getProName("com_infofailed"));
        }

    }});

}
