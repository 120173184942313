module.exports = {
    common: {
        aperture_plus: 'Iris+',
        aperture_minus: 'Iris-',
        focus_plus: 'Focus+',
        focus_minus: 'Focus-',
        amplify_plus: 'Zoom+',
        amplify_minus: 'Zoom-',
        default: 'Reset',
        Fullscreen: 'Full',
        Bitstream: 'Code Stream',
        voice: 'Voice',
        login: 'Login',
        Pan_tilt_speed: 'PT Speed',
        Doubling: 'Zoom Speed',
        Focusing: 'Sensitivity',
        Focus_Area: 'AF Zone',
        Preset: 'Preset',
        mirror: 'Flip',
        brightness: 'Brightness',
        saturation: 'Saturation',
        acutance: 'Sharpness',
        contrast: 'Contrast',
        tone: 'Hue',
        exposure: 'Exposure Mode',
        shutter: 'Max. Shutter',
        gain: 'MAX. Gain',
        Backlight: 'Backlight Comp',
        highlight: 'Highlight Comp',
        ExposureCompensation: 'Expo. Comp',
        FStop: 'Iris value',
        flicker: 'Anti-Flicker',
        whitebalance: 'White Balance',
        redgain: 'R Gain',
        Bluegain: 'G Gain',
        Color: 'Color Temperature',
        tall: 'High',
        centre: 'Middle',
        down: 'Low',
        upper_apex: 'Top',
        hub: 'Center',
        foot: 'Bottom',
        all_over: 'Full Size',
        Main_stream: 'Main-Stream',
        Sub_stream: 'Sub-stream',
        DivX: 'Video Coding',
        Configuration: 'Encode Type',
        resolution: 'Resolution',
        FPs: 'Frame rate',
        Bitratetype: 'Bit-rate Type',
        quality: 'Quality',
        interval: 'GOP',
        code_rate: 'Bit Rate',
        emplace: 'Save',
        freshen: 'Refresh',
        HDMI: 'HDMI Timing',
        firing: 'Enable',
        IPv4site: 'IPV4 Address',
        IPv4mask: 'IPV4 Subnet Mask',
        IPv4gateway: 'IPV4 Gateway',
        IPv6site: 'IPV6 Address',
        IPv6mask: 'IPV6 Subnet Mask',
        IPv6gateway: 'IPV6 Gateway',
        MAC_Address: 'MAC Address',
        prefer: 'Preferred DNS',
        standby: 'Alternative DNS',
        AutomaticallyDNS: 'DNS address',
        AutomaticallyIP: 'Obtain An IP Address Automatically',
        staticIP: 'Static IP',
        staticDNS: 'Static DNS',
        AutogainDNS: 'Obtain DNS Automatically',
        beginOnvif: 'Enable Onvif',
        metadata_alarm: 'Enable Metadata Alarm',
        External_IPV4: 'IPV4 address of the extra-net',
        port: 'Port',
        synchronizetime: 'Allowable Synchronization Time',
        synchronization_parameters: 'Allowable Synchronization Parameter',
        identity_authentication: 'Identity Authentication',
        Video_control: 'Video Control Identifier',
        Audio_control: 'Audio Control Identifier',
        Dialogue_control: 'Talkback Control Identifier',
        Mtu: 'Mtu',
        beginRtmp: 'Enable RTMP',
        Stream_1: 'Main-Stream',
        Stream_2: 'Sub-Stream',
        beginSRT: 'Enable SRT',
        SRTport: 'SRT Port',
        SRTpassword: 'Password',
        SRTpasswordlen: 'Length',
        Password_Length: 'The password is between 10 and 80 characters long',
        devicename: 'Name',
        deviceID: 'ID',
        version: 'Version',
        Equipmentime: 'Device Time',
        ComputerTime: 'PC Time',
        Manualsettings: 'Manually',
        timezone: 'Time Zone',
        openNTP: 'Use NTP',
        NTPIPsite: 'NTP IP',
        hr: 'Hour',
        min: 'Minute',
        update_cycle: 'Timing Refresh Per',
        increase: 'Add',
        username: 'User Name',
        OldPassword: 'Original Password',
        cipher: 'Password',
        Confirm: ' Confirm password',
        group: 'Group',
        modify: 'Change',
        cancel: 'Cancel',
        modul: 'Module',
        resetting: 'Reset',
        ReservedIP: 'Retain Local network',
        Reservedusername: 'Retain Account',
        reservedports: 'Retain Port',
        groupID: 'Group ID',
        realtime: 'Live Video',
        Pantilt: 'PTZ',
        rbac: 'Authority Setup',
        Equipment: 'Parameter',
        reset: 'Reboot',
        MCUversio: 'PTZ',
        Camera: 'SOC',
        Upgradefiles: 'Upgrade File',
        Clickupload: 'Upload',
        promote: 'Upgrade',
        Successfully: 'Setup successfully',
        preferably: 'Best',
        better: 'Better',
        fine: 'Good',
        poor: 'Bad',
        Worse: 'Worse',
        worst: 'Worst',
        open: 'Enable',
        no_unseal: 'No',
        grouping_id: 'Group ID',
        omit: 'Delete',
        collect: 'Modify',
        electric_lens: 'Reset motor lens',
        resetALL: 'Reset All',
        VideoCoding: 'Video Coding',
        networkport: 'Network Port',
        user: 'User',
        Sitelocal: 'Local Network',
        tableau: 'Image',
        bare: 'Exposure',
        voluntarily: 'Auto',
        manual: 'Manual',
        Specify_temperature: 'Specified Color Temperature',
        close: 'Off',
        fliphorizontal: 'Flip H',
        flipvertical: 'Flip V',
        horizontally_vertically: 'Horizontal/Vertical Flip',
        set_up: 'Setup',
        true_time: 'Live View',
        quit: 'Exit',
        VideoSettings: 'Video Settings',
        NetworkSettings: 'Network Settings',
        Systemsettings: 'Maintenance',
        VideoConfig: 'System Settings',
        Videoutput: 'Video Output',
        Local_LAN: 'Local Network',
        SystemInfo: 'Information',
        DateTime: 'Date & Time',
        system_Upgrade: 'System Upgrade',
        USER: 'Account',
        UTC_IDW: '(UTC-12:00)International Date Line West',
        UTC_11: '(UTC-11:00)Coordinated Universal Time -11',
        UTC_HA: '(UTC-10:00)Pacific/Honolulu',
        UTC_Alaska: '(UTC-9:00)America/Anchorage',
        UTC_PS: '(UTC-08:00)America/Los_Angeles',
        UTC_MS: '(UTC-07:00)America/Denver',
        UTC_CA: '(UTC-06:00)America/Mexico_City',
        UTC_IND: '(UTC-05:00)America/Indianapolis',
        UTC_AT: '(UTC-04:00)America/Halifax',
        UTC_BSB: '(UTC-03:00)America/Sao_Paulo',
        UTC_CUT: '(UTC-02:00)Etc/UTC+2',
        UTC_Azores: '(UTC-01:00)Atlantic/Azores',
        UTC_Dublin: '(UTC+00:00)Europe/London',
        UTC_WCA: '(UTC+01:00)Africa/Abidjan',
        UTC_Jerusalem: '(UTC+02:00)Asia/Jerusalem',
        UTC_Moscow: '(UTC+03:00)Europe/Moscow',
        UTC_Louis: '(UTC+04:00)America/Port-au-Prince',
        UTC_ISB: '(UTC+05:00)Asia/Karachi',
        UTC_Dac: '(UTC+06:00)Asia/Dhaka',
        UTC_Bangkok: '(UTC+07:00)Asia/Bangkok',
        UTC_BS: '(UTC+08:00)Asia/Beijing',
        UTC_ST: '(UTC+09:00)Asia/Seoul',
        UTC_Sydney: '(UTC+10:00)Australia/Sydney',
        UTC_Magadan: '(UTC+11:00)Asia/Magadan',
        UTC_Oakland: '(UTC+12:00)Pacific/Auckland',
        UTC_13: '(UTC+13:00)Etc/UTC-13',
        UTC_Kt: '(UTC+14:00)Pacific/Christmas',
        define: 'confirm',
        message: 'Device needs to be restarted， Continue?',
        prompt: 'Reminder',
        accordVec: 'Protocol version',
        Transport: 'Transmission Protocol',
        SIPID: 'SIP server ID',
        SIPdomain: 'SIP server domain',
        SIPaddress: 'SIP server address',
        SIPport: 'SIP service port',
        SIPname: 'SIP username',
        SSOID: 'User authentication ID',
        THETERM: 'Term of validity',
        RegisStatus: 'Registration status',
        Heartbeat: 'Heartbeat cycle',
        LocalSIPort: 'Local SIP port',
        Streamindex: '28181 Stream index',
        Regointerval: 'Registration interval',
        Maxheartbeat: 'Heartbeat times',
        channel: 'Channel No.',
        Vi_cencodingID: 'Video channel code ID',
        SerialNum: 'S.N.',
        name: 'Name',
        labor: 'Operate',
        install: 'Setting',
        mobilize: 'Call',
        RestrictionOne: 'Limited to select one document',
        Confirmremoval: 'Confirm Removal',
        Restarting: 'Restarting',
        Jumppage: 'Jump to login page in seconds',
        enterpass: 'Enter your password',
        PassConfirm: 'Confirm Password ',
        Password_error: 'Passwords don\'t match!',
        Redtuning: 'Rtuning',
        Bluetrim: 'Btuning',
        Presetdot: 'Preset',
        invocation: 'Enable',
        sealin: 'Save',
        UserExist: 'user not exist',
        encodid: 'Code ID',
        Enterpassword: 'Please enter your password again.',
        Repeatpassword: 'Passwords do not match.',
        Line: 'offline',
        online: 'online',
        nullcipher: 'Password confirmation can’t be blank.',
        Confirm_inconsistency: 'Passwords do not match. Please enter again.',
        Deleteuser: 'Are you sure you want to delete this user?',
        Passinconsistency: 'Passwords do not match.',
        Cannotuploadfiles: 'Cannot upload non-Bin files.',
        filesize: 'File size cannot exceed 50MB.',
        IP_the_scope: 'Please enter an IP within the range.',
        no_illegal: 'Do not enter illegal characters.',
        Enternumbers: 'Please enter a number.',
        Consistent_stream: 'The main stream and sub-stream cannot be the same!',
        RangePort: 'Please enter a port within the range.',
        RangeCharacters: 'Please enter characters within the range.',
        Frame_empty: 'Frame rate cannot be empty.',
        Input_interval: 'The value must be within the range.',
        I_frame_empty: 'The I-frame interval cannot be empty.',
        Upload_failed: 'Upload failed.',
        setsrt: 'set faild',
        enteraccount: 'Please enter an account',
        focus_mode: 'Focus Mode',
        manner: 'Graphic Style',
        Standard: 'Standard',
        PC: 'PC',
        Beautiful: 'Beautiful',
        Restart: 'Restart',
        SystemEdition: 'System Edition',
        Copy: 'Copy',
        TimeManually: 'Please enter yyyy-mm-dd hh:mm:ss in the correct time format.',
        DeviceName: 'NDI®Device Name',
        GroupName: 'NDI®Group Name',
        FirmWare: 'NDI®Firmware Version',
        UserDisplay: 'The username cannot be the same!',
        Error: '404错误!',
        NotFound: 'Not Found',
        Error_info: 'Not Found',
    }
}
