/*
 *函数:本地化存储(localStorage) 组件
 * set : function(key, vlaue)
 * get : function(key)
 * remove : function(key)
 * clear : function()
 * each : function(callback) callback接受两个参数 key 和 value obj : function() 返回一个对象描述的localStorage副本
 * length : int
 */
(function(window)
{
    var LS, noop = function(){}, document = window.document, notSupport = {set:noop,get:noop,remove:noop,clear:noop,each:noop,obj:noop,length:0};
    (function(){
        if( "localStorage" in window ){
            try{
                LS = window.localStorage;
                return;
            }catch(e){
            }
        }

        var o = document.getElementsByTagName("head")[0], hostKey = window.location.hostname || "localStorage", d = new Date(), doc, agent;
        if(!o.addBehavior){
            try{
                LS = window.localStorage;
            }catch(e){
                LS = null;
            }
            return;
        }

        try{
            agent = new ActiveXObject('htmlfile');
            agent.open();
            agent.write('<s' + 'cript>document.w=window;</s' + 'cript><iframe src="/favicon.ico"></iframe>');
            agent.close();
            doc = agent.w.frames[0].document;
            o = doc.createElement('head');
            doc.appendChild(o);
        }catch(e){
            o = document.getElementsByTagName("head")[0];
        }

        try{
            d.setDate(d.getDate() + 36500);
            o.addBehavior("#default#userData");
            o.expires = d.toUTCString();
            o.load(hostKey);
            o.save(hostKey);
        }catch(e){
            return;
        }

        var root, attrs;
        try{
            root = o.XMLDocument.documentElement;
            attrs = root.attributes;
        }catch(e){
            return;
        }
        var prefix = "p__hack_", spfix = "m-_-c",
            reg1 = new RegExp("^"+prefix),
            reg2 = new RegExp(spfix,"g"),
            encode = function(key){ return encodeURIComponent(prefix + key).replace(/%/g, spfix); },
            decode = function(key){ return decodeURIComponent(key.replace(reg2, "%")).replace(reg1,""); };
        //创建模拟对象
        LS= {
            length: attrs.length,
            isVirtualObject: true,
            getItem: function(key){
                return (attrs.getNamedItem( encode(key) ) || {nodeValue: null}).nodeValue||root.getAttribute(encode(key));
            },
            setItem: function(key, value){
                try{
                    root.setAttribute( encode(key), value);
                    o.save(hostKey);
                    this.length = attrs.length;
                }catch(e){
                }
            },
            removeItem: function(key){
                try{
                    root.removeAttribute( encode(key) );
                    o.save(hostKey);
                    this.length = attrs.length;
                }catch(e){
                }
            },
            clear: function(){
                while(attrs.length){
                    this.removeItem( attrs[0].nodeName );
                }
                this.length = 0;
            },
            key: function(i){
                return attrs[i] ? decode(attrs[i].nodeName) : undefined;
            }
        };
        if( !("localStorage" in window) )
            window.localStorage = LS;
    })();

    window.LS = !LS ? notSupport : {
        set : function(key, value){
            //fixed iPhone/iPad 'QUOTA_EXCEEDED_ERR' bug
            if( this.get(key) !== undefined )
                this.remove(key);
            LS.setItem(key, value);
            this.length = LS.length;
        },
        get : function(key){
            var v = LS.getItem(key);
            return v === null ? undefined : v;
        },
        remove : function(key){ LS.removeItem(key);this.length = LS.length; },
        clear : function(){ LS.clear();this.length = 0; },
        each : function(callback){
            var list = this.obj(), fn = callback || function(){}, key;
            for(key in list)
                if( fn.call(this, key, this.get(key)) === false )
                    break;
        },

        obj : function(){
            var list={}, i=0, n, key;
            if( LS.isVirtualObject ){
                list = LS.key(-1);
            }else{
                n = LS.length;
                for(; i<n; i++){
                    key = LS.key(i);
                    list[key] = this.get(key);
                }
            }
            return list;
        },
        length : LS.length
    };
    if( window.jQuery ) window.jQuery.LS = window.LS;
})(window);

/*
 *函数:判断是否是window平台支持active 控件
 *参数:无
 *返回值:false和true
 */
export function isWindowsPlatform()
{
    if (!!window.ActiveXObject || "ActiveXObject" in window)
        return true;
    else
        return false;
}

/*
 *函数:获得IE浏览器的版本号
 *参数:无
 *返回值:返回IE的版本号
 */
function getInternetExplorerVersion()
{
    var trim_Version = false;
    var browser = navigator.appName;
    var b_version = navigator.appVersion;
    var UA = b_version || navigator.userAgent;
    if (/msie/i.test(UA))
    {
        trim_Version = UA.match(/msie (\d+\.\d+)/i)[1];
    } else if (~UA.toLowerCase().indexOf('trident') && ~UA.indexOf('rv'))
    {
        trim_Version = UA.match(/rv:(\d+\.\d+)/)[1];
    }

    return trim_Version;
}

/////////////////////URL Opr/////////////////////////////////////////////
/*
 *函数:获得Local storage的参数
 *参数:name获取local storage的名称
 *返回值:key的值
 */
function getvalue(name)
{
    if(name=='lg') {
        var lslg=window.LS.get("lg");
        if(!lslg) return getUrlValue('lg'); else return lslg;
    } else if(name=='version') {
        var ver=window.LS.get("version");
        if(!ver) return getUrlValue('version'); else return ver;
    } else {
        return window.LS.get(name);
    }
    return 0;
}

/*
 *函数:设置Local storage的参数
 *参数:name的key
 *返回值:无
 */
function setvalue(name,val)
{
    window.LS.set(name, val);
}

/*
 *http://192.168.7.201/DeviceInfo/deviceinfo.asp?ID=YWRtaW46YWRtaW4=&lg=1&version=3.0.8
 *函数:获得URL的参数
 *参数:name获取url的名称
 *返回值:具体的url值
 */
function getUrlValue(name)
{
    var str = window.location.search;
    var pos_param = str.indexOf("?");
    if (pos_param != -1) {
        var pos_name = str.indexOf(name, pos_param);
        if (pos_name != -1) {
            var pos_start = pos_name + name.length + 1;
            var pos_end = str.indexOf("&", pos_start);
            if (pos_end == -1)
                return str.substring(pos_start);
            else
                return str.substring(pos_start, pos_end)
        } else {
            return "";
        }
    } else {
        return "";
    }
}


/*
 *函数:获取url的IPV4地址或者IPV6地址
 *参数:无
 *返回值:IPV4地址或者IPV6地址
 */
function getAddress()
{
    var path = window.location.href;
    var beginIPAdress = path.indexOf("//");
    var endIPAdress = path.indexOf("/", beginIPAdress + 2);
    //添加IPV6的地址
    var bgIPv6Index = path.indexOf("[", beginIPAdress + 2);
    var endIPv6Index = path.indexOf("]", beginIPAdress + 2);
    //判断是否是IPV6
    if (bgIPv6Index != -1 && endIPv6Index != -1)
    {
        //IPv6处理的逻辑
        var IPv6Addr = path.substring(bgIPv6Index + 1, endIPv6Index);
        return IPv6Addr;
    }
    //IPV4处理逻辑
    else
    {
        var portPos = path.indexOf(":", beginIPAdress + 2);
        if (portPos < endIPAdress && portPos > -1)
        {
            //包括端口
            return path.substring(beginIPAdress + 2, portPos);
        }
        else
        {
            //不含端口
            return path.substring(beginIPAdress + 2, endIPAdress);
        }
    }
}

/////////////////////common /////////////////////////////////////////////
/*
 *函数:获取websocket端口
 */
 function getWebSocketPort()
 {
     var  flag=false;
     var  port='60001';

     var setobj={"ID":0};
     setobj.ID=getvalue("ID");
     var upnpObj=AjaxSubmit("/getCfgUPNP",setobj);
     if(isHasAttr(upnpObj,'UpnpOpen') && upnpObj.UpnpOpen!=0)
     {
         var netJson=AjaxSubmit("/getCfgNetwork",setobj);
         if(isHasAttr(netJson,'IPAddr') && netJson.IPAddr!= window.location.hostname)
             flag=true;
     }

     if(flag && isHasAttr(upnpObj,'UpnpDataPort'))
     {
         port=upnpObj.UpnpDataPort;
     }else
     {
         var devObj=AjaxSubmit("/getCfgDevicePort",setobj);
         if(isHasAttr(devObj,'WebSocketPort'))
         {
            port=devObj.WebSocketPort;
         }
     }
     return port;
 }

/*
 *函数:判断url是IPV4地址或者IPV6地址
 *参数:无
 *返回值:false true
 */
export function getWebSocketAddress()
{
    var path = window.location.href;
    var IPAddr='';
    var beginIPAdress = path.indexOf("//");
    var endIPAdress = path.indexOf("/", beginIPAdress + 2);
    var bgIPv6Index = path.indexOf("[", beginIPAdress + 1);
    var endIPv6Index = path.indexOf("]", beginIPAdress + 1);
    if (bgIPv6Index != -1 && endIPv6Index != -1)
    {
        IPAddr = 'ws://'+path.substring(bgIPv6Index, endIPv6Index+1)+':'+getWebSocketPort();
    }
    else
    {
        var IPv4Addr='';
        var portPos = path.indexOf(":", beginIPAdress + 2);
        if (portPos < endIPAdress && portPos > -1)
            IPv4Addr=path.substring(beginIPAdress + 2, portPos);
        else
            IPv4Addr=path.substring(beginIPAdress + 2, endIPAdress);
        IPAddr='ws://'+IPv4Addr+':'+getWebSocketPort();
    }
    return IPAddr;
}

/////////////////////string Opr/////////////////////////////////////////////
/*
 *函数:判断字符串存在中文
 *参数:str字符串
 *返回值:false和true
 */
function isAnsi(str)
{
    var i;
    var charCode;
    for (i = 0; i < str.length; i++)
    {
        charCode = str.charCodeAt(i);
        if ((0x0080 <= charCode) && (charCode <= 0x07ff))
        {
            return false;
        }
        else if ((0x0800 <= charCode) && (charCode <= 0xffff))
        {
            return false;
        }
    }
    return true;
}

/*
 *函数:获得字符串utf8的字节实际长度，如: ab你好189  js默认为7个字节
 *参数:str字符串长度
 *返回值:字符串的数目
 */
function getByteNum(str)
{
    var totalLength = 0;
    var i;
    var charCode;
    for (i = 0; i < str.length; i++)
    {
        charCode = str.charCodeAt(i);
        if (charCode < 0x007f)
        {
            totalLength = totalLength + 1;
        }
        else if ((0x0080 <= charCode) && (charCode <= 0x07ff))
        {
            totalLength = totalLength + 2;
        }
        else if ((0x0800 <= charCode) && (charCode <= 0xffff))
        {
            totalLength = totalLength + 3;
        }
    }
    return totalLength;
}

/*
 *函数:按照utf8的字节实际长度，限制字符串，如: ab你好189  js默认为7个字节,若限制4个字节,则是ab你,
 * 判断utf-8编码中字符串的字节，英文字母在utf-8占1个字节，汉字在utf-8中占2-4个字节（一般占3个字节）
 *参数:输入str字符串长度 len限制的长度
 *返回值:符合限制要求的字符串
 */
function limitInputLen(str,len)
{
    var bit=[];
    var realLen = 0;
    var i=0;
    var charCode;
    for (i = 0; i < str.length; i++) {
        charCode = str.charCodeAt(i);
        if (charCode < 0x007f) {
            bit[i]=1;
            realLen = realLen + 1;
        }
        else if ((0x0080 <= charCode) && (charCode <= 0x07ff)) {
            bit[i]=2;
            realLen = realLen + 2;
        }
        else if ((0x0800 <= charCode) && (charCode <= 0xffff)) {
            bit[i]=3;
            realLen = realLen + 3;
        }
    }

    //refactor str
    if(realLen>len) {
        var tmplen=0,cut=0;
        for (i = 0; i < bit.length; i++) {
            tmplen=tmplen+bit[i];
            if(tmplen>len)break;
            cut++;
        }
        str=str.slice(0,cut);
    }
    return str;
}

/*
 *函数:判断是否是0-9数字字符串
 *参数:string字符串长度
 *返回值:false 和 true
 */
function isNumber(string)
{
    var c;
    var ch = "0123456789";

    if (string.length == 0)
        return false;

    for (var i = 0; i < string.length; i++)
    {
        c = string.charAt(i);
        if (ch.indexOf(c) == -1)
            return false;
    }

    return true;
}

/*
 *函数:判断字符串是否是a-z A-Z或数字组成
 *参数:string字符串长度
 *返回值:false 和 true
 */
function alphanumeric(string)
{
    var re = /^[0-9a-zA-Z]*$/g; //判断正整数 /^[1-9]+[0-9]*]*$/
    if (!re.test(string)) {
        return false;
    } else {
        return true;
    }
}

/*
 *函数:判断是否存在特殊字符 '"#$%&\^*
 *参数:string字符串长度
 *返回值:false 和 true
 */
function checkCharacter(string)
{
    if (/['"#$%&\^*]/.test(string))
        return false;
    return true;
}

/*
 *函数:过滤字符串中特殊字符 '"#$%&\^*
 *参数:obj对象
 *返回值:无
 */
function filterspecial(obj)
{
    if (!checkCharacter(obj.value))
    {
        obj.value = obj.value.replace(/['"#$%&\^*]/g, "");
    }
}

/*
 *函数:过滤字符串中不是0-9的字符
 *参数:obj对象
 *返回值:无
 */
function fillterNonNumber(obj)
{
    if (/[^0-9]/.test(obj.value))
    {
        obj.value = obj.value.replace(/[^0-9]/g, "");
    }
}

/*
 *函数:过滤字符串是否是a-z A-Z或数字组成
 *参数:obj对象
 *返回值:无
 */
function fillAlphanumeric(obj)
{
    if (/[^A-Za-z0-9]/.test(obj.value))
    {
        obj.value = obj.value.replace(/[^A-Za-z0-9]/g, "");
    }
}

/*
 *函数:过滤字符串是否是域名和ip组成
 *参数:obj对象
 *返回值:无
 */
function fillAddr(obj)
{
    if (/([^\w-.:%])+/.test(obj.value))
    {
        obj.value = obj.value.replace(/([^\w-.])+/g, "");
    }
}

/*
 *函数:过滤密码
 *参数:obj为对象
 *返回值:无
 */
function fillterPwd(obj)
{
    if (/([\'\"])+/.test(obj.value))
    {
        obj.value = obj.value.replace(/([\'\"])+/g, "");
    }
}

/*
 *函数:判断是否是合法的IPV4地址
 *参数:ip_string字符串长度
 *返回值:false 和 true
 */
function ValidIpAddr(ip_string)
{
    if (ip_string == "")
        return false;

    var exp = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    var reg = ip_string.match(exp);
    if (reg == null)
        return false;

    return true;
}
/*
 *函数:判断是否是合法的IPV6地址
 *参数:ip_string字符串长度
 *返回值:false 和 true
 */
function ValidIpAddrV6(ip_string)
{
    if (ip_string == "")
        return false;

    var exp = /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
    var reg = ip_string.match(exp);
    if (reg == null)
        return false;

    return true;
}
/*
 *函数:解析xml文件或字符串
 *参数:xml格式字符串
 *返回值:xml的doc
 */
function loadXML(txt)
{
    if (window.DOMParser)
    {
        parser=new DOMParser();
        xmlDoc=parser.parseFromString(txt,"text/xml");
    }
    else // Internet Explorer
    {
        xmlDoc=new ActiveXObject("Microsoft.XMLDOM");
        xmlDoc.async="false";
        xmlDoc.loadXML(txt);
    }

    return xmlDoc;
}

/*
 *函数:从小端十六进制字符串获取无符号数值
 *参数:str：字符串 offsetbit：偏移字节数 bit：获取字节数
 *返回值:对应的无符号数值
 */
function GetValueFromHexStr(str,offsetbit,bit)
{
    if(bit!=4 && bit!=2 && bit!=1)
        return 0;
    if(str === "" || (str.length % 2 !== 0) ||  (offsetbit+bit)*2>str.length || bit<=0)
        return 0;
    var cutstr=str.substr(offsetbit*2, bit*2);

    var result = [];
    for(var i=0; i<cutstr.length;i=i+2)
        result.push(cutstr.substr(i, 2));

    return parseInt(result.reverse().join(''),16);
}

/*
 *函数:从小端十六进制字符串获取有符号数值
 *参数:str：字符串 offsetbit：偏移字节数 bit：获取字节数
 *返回值:对应的有符号数值
 */
function GetSymValueFromHexStr(str,offsetbit,bit)
{
    if(bit!=4 && bit!=2 && bit!=1)
        return 0;
    if(str === "" || (str.length % 2 !== 0) ||  (offsetbit+bit)*2>str.length || bit<=0)
        return 0;
    var cutstr=str.substr(offsetbit*2, bit*2);

    var result = [];
    for(var i=0; i<cutstr.length;i=i+2)
        result.push(cutstr.substr(i, 2));

    var hexarray=result.reverse();
    var hexval=parseInt(hexarray.join(''),16);
    var highest=parseInt(hexarray[0],16);

    if((highest&0x80)==0x80)
    {
        var max=Math.pow(2,8*bit)-1;
        hexval=hexval-max-1;
    }
    return hexval;
}

/*
 *函数:从小端十六进制字符串获取字符串
 *参数:str：字符串 offsetbit：偏移字节数 bit：获取字节数
 *返回值:对应的字符串
 */
function GetStrFromHexStr(str,offsetbit,bit)
{
    if(str === "" || (str.length % 2 !== 0) ||  (offsetbit+bit)*2>str.length || bit<=0)
        return 0;
    var cutstr=str.substr(offsetbit*2, bit*2);

    var result = [];
    for(var i=0; i<cutstr.length;i=i+2)
    {
        var hexchar=cutstr.substr(i, 2);
        var asciiCode = parseInt(hexchar,16);
        var charValue = String.fromCharCode(asciiCode);
        result.push(charValue);
    }
    return result.join("");
}

/*
 *函数:将字符串转化为小端十六进制字符串
 *参数:val： 设置的值 bit：设置字节数
 *返回值:十六进制字符串
 */
function ConvertToHexStrEx(str,bit)
{
    var hexstr = new Array(bit);
    for(var i=0;i<bit;i++)
        hexstr[i]='00';

    var list=str.split('');
    for(var i=0;i<list.length;i++)
        hexstr[i]=list[i].charCodeAt().toString(16);

    return hexstr.join('');
}

/*
 *函数:将有符号数值转化为小端十六进制字符串
 *参数:val： 设置的值 bit：设置字节数
 *返回值:十六进制字符串
 */
function ConvertToHexStr(val,bit)
{
    if(bit!=4 && bit!=2 && bit!=1)
        return '';

    var hexstr;
    var val=parseInt(val);
    if(val<0)
    {
        var max=Math.pow(2,8*bit)-1;
        hexstr=(max+val+1).toString(16);
    }else
    {
        hexstr=val.toString(16);
    }

    if(hexstr.length!=bit*2)
    {
        var fill='';
        for(var k=0;k<2*bit-hexstr.length;k++)
            fill = fill+'0';
        hexstr=fill+hexstr;
    }

    var result = [];
    for(var i=0; i<hexstr.length;i=i+2)
        result.push(hexstr.substr(i, 2));
    hexstr=result.reverse().join('');

    return hexstr;
}

/*
 *函数:添加单元tag
 *参数:hexstr：hex字符串 key：关键字 type：数据类型 b：起始位置 e：结束位置
 *返回值:false或true
 */
var tag={key:'',type:'',b:0,e:0,hex:0,val:0};
var hexobj= {hexstr:0,taglist:[]};
function Append(hexstr,key,type,b,e)
{
    if(hexstr === "" || (hexstr.length % 2 !== 0) ||  e>hexstr.length || b<0)
        return false;

    if(!hexobj.hexstr)
        hexobj.hexstr=hexstr;

    var val=0;
    if(type=="char")
        val=GetValueFromHexStr(hexstr,b,1);
    else if(type=="uchar")
        val=GetSymValueFromHexStr(hexstr,b,1);
    else if(type=="short")
        val=GetSymValueFromHexStr(hexstr,b,2);
    else if(type=="ushort")
        val=GetValueFromHexStr(hexstr,b,2);
    else if(type=="int")
        val=GetSymValueFromHexStr(hexstr,b,4);
    else if(type=="uint")
        val=GetValueFromHexStr(hexstr,b,4);
    else if(type=="str")
        val=GetStrFromHexStr(hexstr,b,e-b);
    else
        return false;

    var tag={key:'',type:'',b:0,e:0,hex:0,val:0};
    tag.key=key;
    tag.type=type;
    tag.b=b;
    tag.e=e;
    tag.hex=hexstr.substr(b*2, (e-b)*2);
    tag.val=val;

    hexobj.taglist.push(tag);
    return true;
}

/*
 *函数:获取key的数据
 *参数:key关键词
 *返回值:false或查询到的val
 */
function GetKeyVal(key)
{
    for(var i=0;i<hexobj.taglist.length;i++) {
        if(key === hexobj.taglist[i].key)
            return hexobj.taglist[i].val;
    }
    return false;
}

/*
 *函数:设置key的数据
 *参数:key关键词 val:数据
 *返回值:无
 */
function SetKeyVal(key,val)
{
    for(var i=0;i<hexobj.taglist.length;i++) {
        if(key === hexobj.taglist[i].key) {
            var type=hexobj.taglist[i].type;
            var b=hexobj.taglist[i].b;
            var e=hexobj.taglist[i].e;
            if(type!='str')
                hexobj.taglist[i].hex=ConvertToHexStr(val,e-b);
            else
                hexobj.taglist[i].hex=ConvertToHexStrEx(val,e-b);
            hexobj.taglist[i].val=val;
        }
    }
}

/*
 *函数:获取hex的字符串
 *参数:无
 *返回值:无
 */
function GetHexStr()
{
    for(var i=0;i<hexobj.taglist.length;i++) {
        var b=hexobj.taglist[i].b;
        var e=hexobj.taglist[i].e;
        var hex=hexobj.taglist[i].hex;
        if(hex.length==2*(e-b))
        {
            var front=hexobj.hexstr.substr(0, 2*b);
            var later=hexobj.hexstr.substr(2*e,hexobj.hexstr.length-2*b-hex.length);
            hexobj.hexstr=front + hex + later;
        }

    }
    return hexobj.hexstr;
}

/////////////////////提示信息 Opr/////////////////////////////////////////////
/*
 *函数:提示信息 msgtooltip为提示信息 1s后清除提示信息
 *参数:无
 *返回值:IPV4地址或者IPV6地址
 */

var s_msgid="tooltip";
var s_clearlist=[];
function setMsgId(Id)
{
    s_msgid = Id;
}
function msgtooltip(msg)
{
    var index=-1;
    for(var i = 0;i<s_clearlist.length;i++)
    {
        if(s_clearlist[i].msgid==s_msgid && s_clearlist[i].tooltipflag)
        {
            index=i;
            clearInterval(s_clearlist[i].tooltiptimer);
            s_clearlist[i].tooltiptimer = setTimeout("cleartooltip('" + s_msgid + "')", 1000);
            break;
        }
    }

    if(-1==index)
    {
        var obj={"msgid":0,"tooltipflag":true,"tooltiptimer":0};
        obj.msgid = s_msgid;
        obj.tooltiptimer = setTimeout("cleartooltip('" + s_msgid + "')", 1000);
        s_clearlist.push(obj);
    }

    try {
        document.getElementById(s_msgid).innerHTML = msg;
    } catch (e) {document.getElementById(s_msgid).innerText = msg;}
}

function msgtooltip_ex(msg,sec)
{
    var index=-1;
    for(var i = 0;i<s_clearlist.length;i++)
    {
        if(s_clearlist[i].msgid==s_msgid && s_clearlist[i].tooltipflag)
        {
            index=i;
            clearInterval(s_clearlist[i].tooltiptimer);
            s_clearlist[i].tooltiptimer = setTimeout("cleartooltip('" + s_msgid + "')", sec*1000);
            break;
        }
    }

    if(-1==index)
    {
        var obj={"msgid":0,"tooltipflag":true,"tooltiptimer":0};
        obj.msgid = s_msgid;
        obj.tooltiptimer = setTimeout("cleartooltip('" + s_msgid + "')", sec*1000);
        s_clearlist.push(obj);
    }
    document.getElementById(s_msgid).innerHTML = msg;
}
function cleartooltip(Id)
{
    for(var i = 0;i<s_clearlist.length;i++)
    {
        if(s_clearlist[i].msgid==Id)
        {
            document.getElementById(s_clearlist[i].msgid).innerHTML = "";
            clearInterval(s_clearlist[i].tooltiptimer);
            s_clearlist[i].tooltipflag = false;
            s_clearlist[i]=0;
            break;
        }
    }
}

/*
 * 函数:提示信息, iframe框架第二层提示信息
 * 参数:info为json对象
 */
function PromptMessage(info)
{
    if(!isHasAttr(info, 'State'))
    {
        msgtooltip(getProName("com_infofailed"));
        return;
    }

    switch(info.State)
    {
        case "infoSuccess":msgtooltip(getProName("com_infosuccess"));break;
        case "infoNoAuthority":msgtooltip(getProName("com_infonoprivilege"));break;
        case "infoFailed":msgtooltip(getProName("com_infofailed"));break;
        default:msgtooltip(getProName("com_infofailed"));break;
    }
}

/*
* 函数：iframe框架第三层提示信息
* 参数：info为json对象
* */
function PromptMessageEx(info)
{
    if(!isHasAttr(info, 'State'))
    {
        msgtooltip(getProNameEx("com_infofailed"));
        return;
    }

    switch(info.State)
    {
        case "infoSuccess":msgtooltip(getProNameEx("com_infosuccess"));break;
        case "infoNoAuthority":msgtooltip(getProNameEx("com_infonoprivilege"));break;
        case "infoFailed":msgtooltip(getProNameEx("com_infofailed"));break;
        default:msgtooltip(getProNameEx("com_infofailed"));break;
    }
}

/*
 *函数:显示弹出框提示信息
 *参数:msg为字符串提示信息
 */
function showAlert(msg)
{
    var pop=new Popup({ contentType:4,isReloadOnClose:false,width:340,height:80});
    pop.setContent("title","");
    pop.setContent("alertCon",msg);
    pop.setContent("coverOpacity",'8');
    pop.build();
    pop.show();
}

/////////////////////ajax Opr/////////////////////////////////////////////

/*
 *函数:ajax提交
 *参数:settings配置选项,url data数据 async:同步异步提交方式 false同步 true异步,callback回调
 *返回值:来自服务器的数据
 */
function AjaxSubmitEx(settings)
{
    var def = {
        url:    null,
        data:   {},
        async:  true,
        cb:     null
    };
    settings = $.extend(def, settings);
    if(settings.url == null)
        return;
    if(settings.async && settings.cb == null)
        return;

    if(settings.data!=null) {
        settings.data.VerifyID= getvalue('ID');
        settings.data.ID=settings.data.VerifyID;
    }
    settings.url+="?"+JSON.stringify(settings.data);

    //xml http
    var xmlhttp;
    if (window.XMLHttpRequest)
        xmlhttp = new XMLHttpRequest();
    else
        xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    xmlhttp.open("GET", settings.url, settings.async);
    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
            var text = xmlhttp.responseText;
            var jsonObj = JSON.parse(text);
            if(settings.cb){
                settings.cb(jsonObj);
            }
        }
    };
    xmlhttp.setRequestHeader("If-Modified-Since","0");
    xmlhttp.send(JSON.stringify(settings.data));
}

/*
 *函数:ajax提交
 *参数:uri提交数据
 *返回值:来自服务器的数据
 */
function AjaxSubmit(uri,data)
{
    var objXmlHttpRequest;
    if (window.XMLHttpRequest)// code for IE7+, Firefox, Chrome, Opera, Safari
        objXmlHttpRequest = new XMLHttpRequest();
    else// code for IE6, IE5
        objXmlHttpRequest = new ActiveXObject("Microsoft.XMLHTTP");

    var jsonObj=null;

    if(data!=null)
    {
        data.VerifyID= getvalue('ID');
        data.ID=data.VerifyID;
    }
    uri+="?"+JSON.stringify(data);

    objXmlHttpRequest.open("GET", uri, false);
    objXmlHttpRequest.onreadystatechange = function ()
    {
        if (objXmlHttpRequest.readyState == 4 && objXmlHttpRequest.status == 200)
        {
            var text = objXmlHttpRequest.responseText;
            jsonObj = JSON.parse(text);
            return jsonObj;
        }
    };

    var strjson=JSON.stringify(data);
    objXmlHttpRequest.send(strjson);

    return jsonObj;
}

/////////////////////XML Name Opr/////////////////////////////////////////////
/*
 *函数:获取网页语言文件
 *参数:path为路径  file为文件名
 *返回值:无
 */
function getLanguage(path, file)
{
    var xmllanghttp;
    if (window.XMLHttpRequest)
    {// code for IE7+, Firefox, Chrome, Opera, Safari
        xmllanghttp = new XMLHttpRequest();
    }
    else
    {// code for IE6, IE5
        xmllanghttp = new ActiveXObject("Microsoft.XMLHTTP");
    }

    var lg = getvalue("lg");
    var url = "../language/" + lg + "/" + path + "/" + file + ".xml";

    url += "?" + "version=" + getvalue("version");
    xmllanghttp.open("GET", url, true);
    xmllanghttp.onreadystatechange = function ()
    {
        if (xmllanghttp.readyState == 4 && xmllanghttp.status == 200)
        {
            //var text=xmllanghttp.responseText;
            //alert(text);
            var xmlDoc = xmllanghttp.responseXML.documentElement;
            var allarea = xmlDoc.getElementsByTagName("area");
            //IE10
            for (var i = 0; i < allarea.length; i++)
            {
                var area = allarea[i];
                if(area.getElementsByTagName("id")[0].firstChild != null)
                    var id = area.getElementsByTagName("id")[0].firstChild.nodeValue;
                if(area.getElementsByTagName("title")[0].firstChild != null)
                    var title = area.getElementsByTagName("title")[0].firstChild.nodeValue;
                var startpos = 0;
                var endpos = id.indexOf(',');
                while (endpos != -1)
                {
                    var iditem = id.substring(startpos, endpos);
                    var obj = document.getElementById(iditem);
                    if (obj)
                        obj.innerHTML = title;
                    startpos = endpos + 1;
                    endpos = id.indexOf(',', startpos);
                }
                var iditem = id.substring(startpos);
                var obj = document.getElementById(iditem);
                if (obj)
                    document.getElementById(iditem).innerHTML = title;
                //alert("id="+id+" title="+title);
                //document.getElementById(id).innerHTML=title;
            }
        }
    };
    xmllanghttp.send();
}

/*
 *函数:获取info文件的语言提示，如失败,成功，权限不够等
 *参数:infoid语言标签的ID
 *返回值:无
 */
function getInfomation(infoid)
{
    return getXmlName(infoid,null, "info");
}

/*
 *函数:获取单个的语言标签语言
 *参数: infoid为语言标签id  path为路径  file为文件名
 *返回值:无
 */
function getXmlName(infoid,path, file)
{
    var xmlinfohttp;
    if (window.XMLHttpRequest)
    {// code for IE7+, Firefox, Chrome, Opera, Safari
        xmlinfohttp = new XMLHttpRequest();
    }
    else
    {// code for IE6, IE5
        xmlinfohttp = new ActiveXObject("Microsoft.XMLHTTP");
    }

    var lg = getvalue("lg");
    var url;
    if(path!=null)
        url = "../language/" + lg + "/" + path + "/" + file + ".xml";
    else
        url = "../language/" + lg + "/" + file + ".xml";

    url += "?" + "version=" + getvalue("version");
    xmlinfohttp.open("GET", url, false);

    var result;
    xmlinfohttp.onreadystatechange = function ()
    {
        if (xmlinfohttp.readyState == 4 && xmlinfohttp.status == 200)
        {
            var xmlDoc = xmlinfohttp.responseXML.documentElement;
            var allarea = xmlDoc.getElementsByTagName("area");
            for (var i = 0; i < allarea.length; i++)
            {
                var area = allarea[i];
                if(area.getElementsByTagName("id")[0].firstChild != null)
                    var id = area.getElementsByTagName("id")[0].firstChild.nodeValue;
                if(area.getElementsByTagName("title")[0].firstChild != null)
                    var title = area.getElementsByTagName("title")[0].firstChild.nodeValue;

                if (infoid == id)
                {
                    result = title;
                }
            }
        }
    };
    xmlinfohttp.send();

    return result;
}

/*
 *函数:加载Properties
 *参数: init 是否初始化 level 等级 cb回调
 *返回值:无
 */
function loadProperties(init,level,cb)
{
    if(init) {
        var name=null;
        switch (getvalue('lg')) {
            case '1':name='weblg_zh_cn';break;
            case '2':name='weblg_en';break;
            case '3':name='weblg_ru';break;
            case '10':name='weblg_zh_tw';break;
            case '16':name='weblg_kor';break;
            default:name='weblg_en';break;
        }
        jQuery.i18n.properties({
            name:name,
            path:'../../language/',
            mode:'map',
            language: name,
            callback: cb});
    }
    else {
        cb(level.$.i18n);
    }
}

/*
 *函数:获取系统信息配置,
 *参数:settings: init是否初始化,level:等级
 *返回值:返回系统信息
 */
function getSysInfo(settings)
{
    var defaults = {init: false, level:'parent.parent'};
    settings = $.extend(defaults, settings);
    if(settings.init) {
        var json={"ID":0};
        return AjaxSubmit("/getSysInfoCfg",json);
    }else {
        if(settings.level==='parent.parent')
            return parent.parent._sys;
        if(settings.level==='parent')
            return parent._sys;
        if(settings.level==='.')
            return _sys;
    }
}

/*
 *函数:获取Properties单个的语言标签语言
 *参数: infoid为语言标签id
 *返回值:无
 */
function getProName(id)
{
    var value='';
    function cb(map){ value=map.prop(id); }
    loadProperties(false,parent.parent,cb);
    return value;
}

function getProNameEx(id)
{
    var value='';
    function cb(map){ value=map.prop(id); }
    loadProperties(false,parent.parent.parent,cb);
    return value;
}

/////////////////////Json Opr/////////////////////////////////////////////
/*
 *函数:获得json的长度
 *参数:obj为json对象
 *返回值:返回json对象的长度
 */
function getJsonObjLength(obj)
{
    var size = 0, key;
    for (key in obj)
    {
        if (obj && obj.hasOwnProperty(key))
            size++;
    }
    return size;
}

/*
 *函数:obj是否存在对象attr
 *参数:obj为json对象 attr
 *返回值:返回false或true
 */
function isHasAttr(obj, attr)
{
    //判断是否有该键值
    if (obj && obj.hasOwnProperty(attr))
        return true;

    return false;
}


/////////////////////common /////////////////////////////////////////////
/*
 *函数:获取active的端口，Upnp开启和url的IP与设备IP不相等，则将Upnp的映射端口视为active的端口
 *参数:无
 *返回值:返回正确的active端口
 */
function getActivePort()
{
    var  flag=false;
    var  port='60000';

    var setobj={"ID":0};
    setobj.ID=getvalue("ID");
    var upnpObj=AjaxSubmit("/getCfgUPNP",setobj);
    if(isHasAttr(upnpObj,'UpnpOpen') && upnpObj.UpnpOpen!=0)
    {
        var netJson=AjaxSubmit("/getCfgNetwork",setobj);
        if(isHasAttr(netJson,'IPAddr') && netJson.IPAddr!= window.location.hostname)
            flag=true;
    }

    if(flag && isHasAttr(upnpObj,'UpnpDataPort'))
    {
        port=upnpObj.UpnpDataPort;
    }else
    {
        var devObj=AjaxSubmit("/getCfgDevicePort",setobj);
        if(isHasAttr(devObj,'ListenPort'))
            port=devObj.ListenPort;
    }

    return port;
}

/*
 *函数:判断是否是全景加云台
 *参数:内部参数objRet是有head.js或left.js获取
 *返回值:是返回具体通道值,否返回0
 */
function isPanoPTZ()
{
    var sys=getSysInfo();
    if(isHasAttr(sys,'IsPanoDev') && isHasAttr(sys,'IsDomeDev'))
    {
        if('1'==sys.IsPanoDev && '1'==sys.IsDomeDev && isHasAttr(sys,'ChannelNum'))
            return sys.ChannelNum-1;
    }
    return 0;
}

/*
 *函数:纠正电子哨兵(全景+球机)的播放通道,电子哨兵的PTZ播放播放最后一路,osd和图像配置根据主控通道显示
 *参数:内部参数objRet是有head.js或left.js获取,ch是纠正球机通道,type:1 osd 2 img
 *返回值:是返回具体通道值
 */
function correctPanoPTZ(chn,type)
{
    var sys=getSysInfo();
    if(isHasAttr(sys,'IsPanoDev') && '1'==sys.IsPanoDev)
    {
        if(isHasAttr(sys,'ChannelNum') && '1'==chn)
            return sys.ChannelNum-1;

        var send={"ID":0,"getChannel":0};
        send.ID=getvalue("ID");
        var obj=AjaxSubmit("/GetMasterSensor",send);

        if(type==1 && isHasAttr(obj,'OSDMaster'))
            return obj.OSDMaster;
        if(type==2 && isHasAttr(obj,'MasterSensor'))
            return obj.MasterSensor;
    }
    return chn;
}

/*
 *函数:获取当前的播放码流的通道
 *参数:内部参数objRet是有head.js或left.js获取
 *返回值:是返回具体码流通道,0:主码流 1:次码流
 */
function getVideoStream()
{
    var stream=0;
    try {
        var sys=getSysInfo();
        if(isHasAttr(sys,'StreamNum')) {
            if(parseInt(sys.StreamNum)>=3)
                stream=1;
        }
    } catch (e) {}
    return stream;
}

/*
 *函数:判断是否旋转
 *参数:无
 *返回值:是否旋转
 */
function isRotate()
{
    var json={"Channel":0,"ID":0,"Stream":1};
    json.ID=getvalue("ID");
    var vec= AjaxSubmit("/GetCfgVEnc",json);
    if('0'==vec.RotateMode || '2'==vec.RotateMode)//0 180
        return false;
    return true;
}

/*
 *函数:刷新数据
 *参数:无
 *返回值:无
 */
function onRefresh()
{
    var ifr= parent.$("#rightiframe");
    var hrf=ifr.attr( "src");
    if(ifr.css("visibility")!="hidden")
        ifr.css("visibility","hidden");
    ifr.attr("src", hrf);
}

/*
 *函数:获取播放适应宽高
 *参数:chn:通道 stream:码流 rotate:是否旋转 lw:限制宽度 lh:限制高度 stretch:是否拉伸
 *返回值:播放适应宽高
 */
function getPalySize(settings)
{
    var defaults = {chn: 0,stream:1,rotate:true,lw:$(window).width(),lh:$(window).height(),stretch:false};
    settings = $.extend(defaults, settings);

    var json={"Channel":0,"ID":0,"Stream":1};
    json.ID=getvalue("ID");
    json.Channel=settings.chn;
    json.Stream=settings.stream;
    var vec= AjaxSubmit("/GetCfgVEnc",json);
    var imgw=vec.Vec[vec.NowStream].ImageWidth;
    var imgh=vec.Vec[vec.NowStream].ImageHeight;
    if(settings.rotate) {
        if('1'==vec.RotateMode || '3'==vec.RotateMode){//90 270
            imgw = vec.Vec[vec.NowStream].ImageHeight;
            imgh = vec.Vec[vec.NowStream].ImageWidth;
        }
    }
    //最大公约数
    var a=imgw,b=imgh;
    var temp = 0;
    while(b != 0){temp = a % b;a = b;b = temp;}

    var calw=0,calh=0;
    if(settings.stretch)
    {
        //原始比例：在限制比例范围内,拉伸放大
        calw=(imgw/a)*Math.floor(settings.lw/(imgw/a));
        calh=(imgh/a)*Math.floor(settings.lh/(imgh/a));
    }
    else
    {
        //原始比例：在限制比例范围内,高固定宽比例放大
        calh = settings.lh;
        calw = (imgw/a)*settings.lh/(imgh/a);
    }
    return {'w':calw,'h':calh};
}

/*
 *函数：画框
 *参数：rect为结构体 canvas对象
 *返回值：无
 */
 function drawRect(rect,canvas)
 {
     var ctx     = canvas.getContext('2d');
     ctx.clearRect(0, 0, canvas.width, canvas.height);
     ctx.beginPath();
     ctx.strokeRect(rect.X, rect.Y, rect.Width, rect.Height);
 }

 /*
 *函数：画框数据百分比比例转换
 *参数：rect为结构体 actWidth为实际的canvas宽度 actHeight为实际的canvas高度
 *返回值：无
 */
 function rectGetInfo(rect,actWidth,actHeight)
{
    if(rect.X<0) rect.X=0;
    if(rect.Y<0) rect.Y=0;

    if(rect.Width<0 || rect.Height<0) {
        if(rect.Width<0 && rect.Height<0) {
            rect.X += rect.Width;
            rect.Y += rect.Height;
        } else if(rect.Width<0 && rect.Height>0) {
            rect.X += rect.Width;
        } else if(rect.Width>0 && rect.Height<0) {
            rect.Y += rect.Height;
        }
    }

    rect.X      = Math.round(rect.X/actWidth*100);
    rect.Y      = Math.round(rect.Y/actHeight*100);
    rect.Width  = Math.round(Math.abs((rect.Width/actWidth)*100));
    rect.Height = Math.round(Math.abs((rect.Height/actHeight)*100));
}
