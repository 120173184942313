

/***********************************************************************************************************************
 *@ @WebGL rendering
 **********************************************************************************************************************/

// function G() {
//     var elements = new Array();
//     for (var i = 0; i < arguments.length; i++) {
//         var element = arguments[i];
//         if (typeof element == 'string')
//             element = document.getElementById(element);
//         if (arguments.length == 1)
//             return element;
//         elements.push(element);
//     }
//     ;
//     return elements;
// }
// ;

function Texture(gl) {
    this.gl = gl;
    this.texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, this.texture);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
}

Texture.prototype.bind = function (n, program, name) {
    var gl = this.gl;
    gl.activeTexture([gl.TEXTURE0, gl.TEXTURE1, gl.TEXTURE2][n]);
    gl.bindTexture(gl.TEXTURE_2D, this.texture);
    gl.uniform1i(gl.getUniformLocation(program, name), n);
}

Texture.prototype.fill = function (width, height, data) {
    var gl = this.gl;
    gl.bindTexture(gl.TEXTURE_2D, this.texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.LUMINANCE, width, height, 0, gl.LUMINANCE, gl.UNSIGNED_BYTE, data);
}

function WebGLPlayer(canvas) {
    this.canvas = canvas;
    this.gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    this.initGL();
}

WebGLPlayer.prototype.initGL = function () {
    if (!this.gl) {
        console.log("[ER] WebGL not supported.");
        return;
    }

    var gl = this.gl;
    gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);
    var program = gl.createProgram();
    var vertexShaderSource = [
        "attribute highp vec4 aVertexPosition;",
        "attribute vec2 aTextureCoord;",
        "varying highp vec2 vTextureCoord;",
        "void main(void) {",
        " gl_Position = aVertexPosition;",
        " vTextureCoord = aTextureCoord;",
        "}"
    ].join("\n");
    var vertexShader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vertexShader, vertexShaderSource);
    gl.compileShader(vertexShader);
    var fragmentShaderSource = [
        "precision highp float;",
        "varying lowp vec2 vTextureCoord;",
        "uniform sampler2D YTexture;",
        "uniform sampler2D UTexture;",
        "uniform sampler2D VTexture;",
        "const mat4 YUV2RGB = mat4",
        "(",
        " 1.1643828125, 0, 1.59602734375, -.87078515625,",
        " 1.1643828125, -.39176171875, -.81296875, .52959375,",
        " 1.1643828125, 2.017234375, 0, -1.081390625,",
        " 0, 0, 0, 1",
        ");",
        "void main(void) {",
        " gl_FragColor = vec4( texture2D(YTexture, vTextureCoord).x, texture2D(UTexture, vTextureCoord).x, texture2D(VTexture, vTextureCoord).x, 1) * YUV2RGB;",
        "}"
    ].join("\n");

    var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fragmentShader, fragmentShaderSource);
    gl.compileShader(fragmentShader);
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    gl.useProgram(program);
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        console.log("[ER] Shader link failed.");
    }
    var vertexPositionAttribute = gl.getAttribLocation(program, "aVertexPosition");
    gl.enableVertexAttribArray(vertexPositionAttribute);
    var textureCoordAttribute = gl.getAttribLocation(program, "aTextureCoord");
    gl.enableVertexAttribArray(textureCoordAttribute);

    var verticesBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, verticesBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([1.0, 1.0, 0.0, -1.0, 1.0, 0.0, 1.0, -1.0, 0.0, -1.0, -1.0, 0.0]), gl.STATIC_DRAW);
    gl.vertexAttribPointer(vertexPositionAttribute, 3, gl.FLOAT, false, 0, 0);
    var texCoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([1.0, 0.0, 0.0, 0.0, 1.0, 1.0, 0.0, 1.0]), gl.STATIC_DRAW);
    gl.vertexAttribPointer(textureCoordAttribute, 2, gl.FLOAT, false, 0, 0);

    gl.y = new Texture(gl);
    gl.u = new Texture(gl);
    gl.v = new Texture(gl);
    gl.y.bind(0, program, "YTexture");
    gl.u.bind(1, program, "UTexture");
    gl.v.bind(2, program, "VTexture");
},

    WebGLPlayer.prototype.renderFrame = function (videoFrame, width, height, uOffset, vOffset) {
        if (!this.gl) {
            console.log("[ER] Render frame failed due to WebGL not supported.");
            return;
        }

        var gl = this.gl;
        gl.canvas.width = this.canvas.clientWidth;
        gl.canvas.height = this.canvas.clientHeight;
        gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
        gl.clearColor(0.0, 0.0, 0.0, 0.0);
        gl.clear(gl.COLOR_BUFFER_BIT);

        gl.y.fill(width, height, videoFrame.subarray(0, uOffset));
        gl.u.fill(width >> 1, height >> 1, videoFrame.subarray(uOffset, uOffset + vOffset));
        gl.v.fill(width >> 1, height >> 1, videoFrame.subarray(uOffset + vOffset, videoFrame.length));

        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    }

    // 修改过------叶

/***********************************************************************************************************************
*@ @PCM player
**********************************************************************************************************************/
const PCMPlayer = function (option) {
    this.init(option);
}

PCMPlayer.prototype.init = function (option) {
    var defaults = {
        encoding: '16bitInt',
        channels: 1,
        sampleRate: 8000,
        flushingTime: 1000
    };
    this.option = Object.assign({}, defaults, option);
    this.samples = new Float32Array();
    this.flush = this.flush.bind(this);
    this.interval = setInterval(this.flush, this.option.flushingTime);
    this.maxValue = this.getMaxValue();
    this.typedArray = this.getTypedArray();
    this.createContext();
};

PCMPlayer.prototype.getMaxValue = function () {
    var encodings = {
        '8bitInt': 128,
        '16bitInt': 32768,
        '32bitInt': 2147483648,
        '32bitFloat': 1
    }

    return encodings[this.option.encoding] ? encodings[this.option.encoding] : encodings['16bitInt'];
};

PCMPlayer.prototype.getTypedArray = function () {
    var typedArrays = {
        '8bitInt': Int8Array,
        '16bitInt': Int16Array,
        '32bitInt': Int32Array,
        '32bitFloat': Float32Array
    }

    return typedArrays[this.option.encoding] ? typedArrays[this.option.encoding] : typedArrays['16bitInt'];
};

PCMPlayer.prototype.createContext = function () {
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    // context needs to be resumed on iOS and Safari (or it will stay in "suspended" state)
    this.audioCtx.resume();
    //this.audioCtx.onstatechange = () => console.log(this.audioCtx.state);
    this.gainNode = this.audioCtx.createGain();
    this.gainNode.gain.value = 1;
    this.gainNode.connect(this.audioCtx.destination);
    this.startTime = this.audioCtx.currentTime;
};

PCMPlayer.prototype.isTypedArray = function (data) {
    return (data.byteLength && data.buffer && data.buffer.constructor == ArrayBuffer);
};

PCMPlayer.prototype.feed = function (data) {
    if (!this.isTypedArray(data)) return;
    data = this.getFormatedValue(data);
    var tmp = new Float32Array(this.samples.length + data.length);
    tmp.set(this.samples, 0);
    tmp.set(data, this.samples.length);
    this.samples = tmp;
};

PCMPlayer.prototype.getFormatedValue = function (data) {
    var data = new this.typedArray(data.buffer),
        float32 = new Float32Array(data.length),
        i;

    for (i = 0; i < data.length; i++) {
        float32[i] = data[i] / this.maxValue;
    }
    return float32;
};

PCMPlayer.prototype.volume = function (volume) {
    this.gainNode.gain.value = volume;
};

PCMPlayer.prototype.destroy = function () {
    if (this.interval) {
        clearInterval(this.interval);
    }
    this.samples = null;
    this.audioCtx.close();
    this.audioCtx = null;
};

PCMPlayer.prototype.flush = function () {
    if (!this.samples.length) return;
    var bufferSource = this.audioCtx.createBufferSource(),
        length = this.samples.length / this.option.channels,
        audioBuffer = this.audioCtx.createBuffer(this.option.channels, length, this.option.sampleRate),
        audioData,
        channel,
        offset,
        i,
        decrement;

    for (channel = 0; channel < this.option.channels; channel++) {
        audioData = audioBuffer.getChannelData(channel);
        offset = channel;
        decrement = 50;
        for (i = 0; i < length; i++) {
            audioData[i] = this.samples[offset];
            /* fadein */
            if (i < 50) {
                audioData[i] = (audioData[i] * i) / 50;
            }
            /* fadeout*/
            if (i >= (length - 51)) {
                audioData[i] = (audioData[i] * decrement--) / 50;
            }
            offset += this.option.channels;
        }
    }

    if (this.startTime < this.audioCtx.currentTime) {
        this.startTime = this.audioCtx.currentTime;
    }
    //console.log('start vs current '+this.startTime+' vs '+this.audioCtx.currentTime+' duration: '+audioBuffer.duration);
    bufferSource.buffer = audioBuffer;
    bufferSource.connect(this.gainNode);
    bufferSource.start(this.startTime);
    this.startTime += audioBuffer.duration;
    this.samples = new Float32Array();
};

/***********************************************************************************************************************
 *@ WASM Preview
 **********************************************************************************************************************/
function G() {
    var elements = new Array();
    for (var i = 0; i < arguments.length; i++) {
        var element = arguments[i];
        if (typeof element == 'string')
            element = document.getElementById(element);
        if (arguments.length == 1)
            return element;elements.push(element);
    };
    return elements;
};

export var WasmPreiview = /** @class */ (function () {
    function WasmPreiview(probeData) {
        this._probeData = probeData;
        this._wasmplayer = null;
        this._webglPlayer = null;
        this._pcmPlayer = null;
        this._audioAttr = null;
        this._canvas = G('canvasplay');
        this.openWasmplayer();
    }

    WasmPreiview.prototype.openWasmplayer = function () {
        if (!this._wasmplayer) {
            var self = this;
            this._wasmplayer = new Worker("../js/wasmplayer.js");
            this._wasmplayer.onmessage = function (evt) {
                var objData = evt.data;
                switch (objData.t) {
                    case 'onWasmLoad':
                        self._onWasmLoad();
                        break;
                    case 'onVideoFrame':
                        self._onVideoFrame(objData.d, objData.w, objData.h);
                        break;
                    case 'onAudioFrame':
                        self._onAudioFrame(objData.d, objData.s, objData.b, objData.c);
                        break;
                    case 'onCloseAudio':
                        self._onCloseAudio();
                        break;
                    case 'onDestroy':
                        self._onDestroy();
                        break;
                }
            }
        }
        this._wasmplayer.postMessage({
            t: 'onProbeData', d: this._probeData
        });
    };
    WasmPreiview.prototype._onWasmLoad = function () {
        console.log("wasmplayer load complete!");
    };
    WasmPreiview.prototype._onVideoFrame = function (frame, width, height) {
        var lenY = width * height, lenUV = (width / 2) * (height / 2);
        var size = width * height * 3 / 2;
        this._webglPlayer.renderFrame(frame, width, height, lenY, lenUV);
    };

    WasmPreiview.prototype._onAudioFrame = function (frame, sampleRate, bitRate, channels) {
        var pcmPlayer = this._pcmPlayer;
        if (pcmPlayer) {
            var audio = this._audioAttr;
            if (audio.sampleRate != sampleRate || audio.bitRate != bitRate || audio.channels != channels) {
                pcmPlayer.destroy();
                pcmPlayer = null;
            }
        }
        if (!pcmPlayer) {
            this._audioAttr = {'sampleRate': sampleRate, 'bitRate': bitRate, 'channels': channels};
            var audio = this._audioAttr;
            pcmPlayer = this._pcmPlayer = new PCMPlayer({
                encoding: (audio.bitRate == 16) ? '16bitInt' : '8bitInt',
                channels: audio.channels,
                sampleRate: audio.sampleRate,
                flushingTime: 500
            });
        }
        if (pcmPlayer) {
            pcmPlayer.feed(frame);
        }
    };
    WasmPreiview.prototype._onDestroy = function () {
        console.log("onDestroy")
        if (this._webglPlayer) {
            this._webglPlayer = null;
        }
        if (this._pcmPlayer) {
            this._pcmPlayer = null;
        }
        if (this._audioAttr) {
            this._audioAttr = null;
        }
    };
    WasmPreiview.prototype._onCloseAudio = function () {
        if (this._pcmPlayer) {
            this._pcmPlayer = null;
        }
        if (this._audioAttr) {
            this._audioAttr = null;
        }
    };
    WasmPreiview.prototype.videoPlay = function (e) {
        if (!this._webglPlayer) {
            this._webglPlayer = new WebGLPlayer(this._canvas);
        }
        if (this._wasmplayer) {
            this._wasmplayer.postMessage({
                t: 'onVideoPlay'
            });
        }
    };
    WasmPreiview.prototype.startAudio = function (e) {
        if (this._wasmplayer) {
            this._wasmplayer.postMessage({
                t: 'onStartAudio'
            });
        }
    };
    WasmPreiview.prototype.closeAudio = function (e) {
        if (this._wasmplayer) {
            this._wasmplayer.postMessage({
                t: 'onCloseAudio'
            });
        }
    };
    WasmPreiview.prototype.destroy = function (e) {
        if (this._wasmplayer) {
            this._wasmplayer.postMessage({
                t: 'OnDestroy'
            });
        }
    };
    return WasmPreiview;
}());


/***********************************************************************************************************************
 *@ WASM
 **********************************************************************************************************************/
export var wasmjs = {};
wasmjs.createPlayer = createPlayer;
wasmjs.isSupported = isSupported;

function isSupported() {
    if (typeof (WebSocket) === 'undefined') {
        console.warn("The browser does not support websocket!");
        return false;
    }
    if (!window.Worker) {
        console.warn("The browser does not support new worker!");
        return false;
    }
    return true;
}

export function createPlayer(mediaDataSource) {
    var mds = mediaDataSource;
    if (mds == null || typeof mds !== 'object') {
        throw new _utils_exception_js__WEBPACK_IMPORTED_MODULE_8__.InvalidArgumentException('MediaDataSource must be an javascript object!');
    }
    if (!mds.hasOwnProperty('type')) {
        throw new _utils_exception_js__WEBPACK_IMPORTED_MODULE_8__.InvalidArgumentException('MediaDataSource must has type field to indicate video file type!');
    }
    switch (mds.type) {
        case 'play':
            return new WasmPreiview(mds);
        case 'upgrade':
        //return new WasmUpgrade(mds);
        case 'record':
        //return new WasmRecord(mds);
    }
}
