<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import Cookies from "js-cookie";

export default {
  name: 'App',
  data() {
    return {
      timer: null,

    };
  },

  mounted() {

  },

  methods: {

  },

}
</script>

<style>
html {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

html::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar {
  display: none;
}

*{
  margin: 0;
  padding: 0;
}

html,body {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 19px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: white;
  background: #373A48;
  height: 100%;
}

.el-tabs__item {
  color: white;
}

.el-input__inner {
  color: white;
  background-color: #373A48;
}

.succed {
  top: 50px;
}

.el-tabs__content {
  width: 100%;
  height: 96%;
  overflow-y: auto;
}

</style>
